
import { NewClient } from "@interfaces";
import { AxiosInstance } from "axios";

const createClient = async (client: NewClient, axiosInstance: AxiosInstance) => {

        try {
            const response = await axiosInstance
                .post('/client', {
                    ...client
                });
                
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            } else {
                return error.message;
            }
        }  
}

export {createClient};