import React from 'react';

import { FormHelperText } from '@mui/material';
import { CepProps } from '@interfaces';

const FormHelperCEP: React.FC<{ address: CepProps }> = ({ address }: { address: CepProps }) => {

    return (
        <>
            {address.cep?.length > 0 ? (
                <FormHelperText error={false} id="response-success-cep">
                    CEP {address.cep}{", "}
                    {address.street}{", "}
                    {address.neighborhood}{", "}
                    {address.city}
                </FormHelperText>
            ) : ''}

            {address.error && (
                <FormHelperText error id="response-error-cep">
                    CEP Inválido!
                </FormHelperText>
            )}
        </>
    )
}

export { FormHelperCEP };