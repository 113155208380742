import React from 'react';
import { SxProps, Typography, useTheme } from '@mui/material';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { IconWrapper, Button } from './styles';

import { sx } from './sx';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useNavigate } from 'react-router-dom';

interface MenuItemProps {
    icon: string;
    iconType: 'fas' | 'fal' | 'far';
    color: string;
    fontSize: string;
    text: string;
    link?: string;
    disabled?: boolean;
    active?: boolean;
    onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps) => {

    const {
        icon,
        iconType,
        color,
        fontSize,
        text,
        disabled,
        link,
        active,
        onClick
    } = props;

    const { palette, typography } = useTheme();

    const navigate = useNavigate();

    const { css } = sx();

    return (
        <Button
            type="button"
            disabled={disabled}
            onClick={onClick ? onClick : () => navigate(`${link}`)}
            className={`${active ? 'active' : ''}`}
        >

            <IconWrapper>
                <I icon={[iconType, icon as IconName]} color={color} fontSize={fontSize} />
            </IconWrapper>
            <Typography
                align='left'
                variant='body1'
                component='h1'
                sx={{ ...css.label as SxProps, color: active ? palette.atacadao.colors.primary[30] : palette.grey[600] }}
            >
                {text}
            </Typography>
        </Button>
    )
}

export { MenuItem };