import { AxiosInstance } from 'axios';

const logout = async (axiosInstance: AxiosInstance) => {
    try {
        const response = await axiosInstance.post('/client/logout');

        return response.data;
        
    } catch (error: any) {
        if (error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        } else {
            return error.message;
        }
    }
}

export { logout }