const pathURL = (title: string): string => {
    
    const text = title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^A-Z0-9]+/ig, "-")
        .toLowerCase();

    return text;
}

export { pathURL };