import React from 'react';

import { DoneSuccess } from '@design-system';

import { useNavigate } from 'react-router-dom';

const SecurityCodeDone: React.FC = () => {

    const navigate = useNavigate();

    const goto = (url: string) => setTimeout(() => navigate(url), 1000);

    React.useEffect(() => {
        goto('/pre');
    }, []);

    return (

        <React.Fragment>
            <DoneSuccess
                title='Código de verificado!'
            />
        </React.Fragment>
    );
}

export { SecurityCodeDone };