import React from 'react';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import $ from "jquery";
import "jquery-mask-plugin";

import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material';
import {
  Header,
  MuiContainer,
  Form,
} from './styles';

import { AppLogo, BottomPageAction, FormHelperCEP } from '@design-system';
import { useAuth, useUserAddress } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type DocumentType = 'CPF' | 'CNPJ';

interface IFormInputs {
  document: string;
  documentType: DocumentType;
  cellphone: string;
  zipcode: string;
}

const schema = yup.object({
  document: yup.string().required("Campo obrigatório"),
  documentType: yup.string().required("Tipo de pessoa"),
  cellphone: yup.string().required("Digite um número de telefone"),
  zipcode: yup.string().required("Digite um cep válido"),
}).required();

const CreateAccount: React.FC = () => {

  const navigate = useNavigate();
  const { palette, typography } = useTheme();

  const { createAccount, promotionCode, loadUser } = useAuth();

  const [docType, setDocType] = React.useState<DocumentType>('CPF');

  const { setCep, address } = useUserAddress();

  const { register, handleSubmit, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async ({ cellphone, document, documentType, zipcode }: IFormInputs) => {

    const host = window.location.host;
    const subdomain = host.split(".")[0];

    // organizationCode pegar do subdominio
    const send = {
      organizationCode: subdomain, //'atacadao'
      promotionCode: promotionCode,
      document: document,
      documentType: documentType,
      cellphone: cellphone,
      address: {
        zipcode: zipcode,
        state: address.state
      }

    }

    if (!address.error) {

      try {
        const response = await createAccount({ data: send });

        if (response.status >= 401) throw new Error(response.message);

        await loadUser();
        navigate('/create-account/done');

      } catch (error: any) {
        toast.error(error.message, { position: 'bottom-center' });
      }
    }

  };

  React.useEffect(() => {
    $('.cep').mask('00000-000') as any;
    $('.phone_with_ddd').mask('(00) 00000-0000');
    $('.cpf').mask('000.000.000-00', { reverse: true });
    $('.cnpj').mask('00.000.000/0000-00', { reverse: true });
  }, [docType]);
  
  return (

    <Form onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <AppLogo align='center' />
      </Header>

      <MuiContainer maxWidth="sm">

        <Box
          sx={{
            margin: '1.875rem 0'
          }}>

          <Typography
            align='center'
            variant='body1'
            component='h1'
            sx={{
              color: palette.grey[900],
              fontWeight: typography.fontWeightMedium,
              fontSize: '1.5rem',
            }}
          >
            Complete seus dados
          </Typography>

          <Typography
            align='center'
            variant='body1'
            component='p'
            sx={{
              color: palette.grey[800],
              fontWeight: typography.fontWeightRegular,
              fontSize: '16px',
            }}
          >
            Antes de prosseguir precisamos de algumas
            informações suas.
          </Typography>
        </Box>

        <Typography
          align='left'
          variant='body1'
          component='h1'
          sx={{
            fontWeight: typography.fontWeightMedium,
            fontSize: '1rem',
            color: palette.grey[600],
            marginTop: '1.875rem',
            marginBottom: '.9375rem',
          }}
        >
          Informe seus dados
        </Typography>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">

              <FormLabel id="demo-radio-buttons-group-label">Pessoa</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="CPF"
              >
                <FormControlLabel
                  value="CPF"
                  control={
                    <Radio {...register("documentType", { required: true, })}
                      onChange={(e) => setDocType(e.target.value as DocumentType)
                      } />
                  } label="Fisíca"
                />

                <FormControlLabel
                  value="CNPJ"
                  control={
                    <Radio {...register("documentType", { required: true, })}
                      onChange={(e) => setDocType(e.target.value as DocumentType)
                      } />
                  }
                  label="Jurídica"
                />

              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>

            <FormControl error fullWidth variant="standard">
              <TextField
                id="filled-basic-cpf"
                label={docType}
                error={!!errors.document}
                fullWidth
                variant="filled"
                focused
                inputProps={{ className: docType === "CPF" ? "cpf" : "cnpj" }}
                {...register("document", { required: true, })}
              />
              <FormHelperText id="filled-basic-cpf">
                {errors.document?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl error fullWidth variant="standard">
              <TextField
                id="filled-basic-phone"
                error={!!errors.cellphone}
                label="Telefone"
                fullWidth
                variant="filled"
                focused
                inputProps={{ className: 'phone_with_ddd' }}
                {...register("cellphone", { required: true, })}
              />

              <FormHelperText id="filled-basic-phone">
                {errors.cellphone?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl error fullWidth variant="standard">
              <TextField
                id="filled-basic-cep"
                label="CEP"
                error={!!errors.zipcode}
                fullWidth
                focused
                variant="filled"
                aria-describedby="component-error-text"
                inputProps={{ className: 'cep' }}
                {...register("zipcode",
                  {
                    required: true,
                    onChange: setCep
                  })
                }
              />

              <FormHelperText id="filled-basic-cep">
                {errors.zipcode?.message}
              </FormHelperText>

              <FormHelperCEP address={address} />
            </FormControl>
          </Grid>


        </Grid>

      </MuiContainer>
      <BottomPageAction>
        <Button
          className='mui-primary'
          variant="contained"
          type='submit'
          disabled={!!address.isLoading}
        >
          Salvar
        </Button>
      </BottomPageAction>
    </Form>
  )
}

export { CreateAccount };