import { useTheme } from "@mui/material";

export const sx = () => {

    const { palette, typography } = useTheme();

    const css = {
        icon: {
            size: '1.25rem',
            color: palette.grey[700]
        },
        label: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '9px',
            color: palette.grey[600],
            textAlign: 'center'
        }
    }

    return { css }
}