import { Container, alpha, styled } from "@mui/material";

export const MuiContainer = styled(Container)`
    padding-bottom: 1.875rem;
    height: calc(100% - 242px);
    overflow-y: auto;
`;

export const Header = styled('div')`
    background-color: ${({ theme }) => theme.palette.common.white};
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    padding: .875rem .9375rem;
`;

export const Columns = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

export const Column = styled('div')`
`;

export const Icon = styled('div')`
    background-color: ${({ theme }) => alpha(theme.palette.atacadao.colors.primary[30], 0.1)};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    width: 2.75rem;
    border-radius: .1875rem;
    margin-right: .625rem;
`;