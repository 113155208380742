import { styled } from "@mui/material";

const bg = 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8827731776304272) 25%, rgba(255,255,255,0.571848807882528) 75%, rgba(255,255,255,0) 100%)';

export const Main = styled('div')`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${bg};
    z-index: 999;
`;


export const Menu = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${theme.palette.common.white};
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
`);