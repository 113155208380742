import { createContext } from "react";

import { backendAPI } from '@services';

interface ResponseDataProps {
    data: {
        message: string;
    };
    status: number;
}

interface EmailProps {
    message: string;
    status: number;
}

export interface UserContextData {
    updateAccount: ({ email, zipcode }: {
        email: string;
        zipcode: string;
        state: string;
    }) => Promise<ResponseDataProps>;
    validateEmailToken: ({ token }: {
        token: string;
    }) => Promise<EmailProps>;
    resendEmailValidationToken: () => Promise<EmailProps>
}

const UserContext = createContext<UserContextData>({} as UserContextData);

export const UserProvider: React.FC<any> = ({ children }) => {

    const updateAccount = async ({ email, zipcode, state }: { email: string; zipcode: string; state: string }) => {

        let response = {} as ResponseDataProps;

        try {

            const responseData: ResponseDataProps = await backendAPI.client.update(email, zipcode, state);

            if (responseData as any === "Erros de validação encontrados") throw new Error("EVCE: Erros de validação encontrados");

            response = {
                ...responseData,
                status: 200
            }

        } catch (error: any) {

            response = {
                data: { message: error?.message },
                status: 400
            }

        } finally {
            return response;
        }

    }

    const validateEmailToken = async ({ token }: { token: string }) => {

        let response = {} as EmailProps;

        try {

            const responseData = await backendAPI.client.confirmEmail(token) as EmailProps;

            const isLoaded = (!!responseData.message);

            if (!isLoaded) throw new Error('Link de confirmação de e-mail inválido ou expirado!');

            response = {
                message: responseData.message,
                status: 200
            }


        } catch (error: any) {
            response = {
                message: error.message,
                status: 400
            }
        } finally {
            return response;
        }


    }

    const resendEmailValidationToken = async () => {
        let response = {} as EmailProps;

        try {

            const responseData = await backendAPI.client.resendConfirmationEmail() as EmailProps;

            response = {
                message: responseData.message,
                status: 200
            }

        } catch (error: any) {
            response = {
                message: error.message,
                status: 200
            }

        } finally {
            return response;
        }
    }

    return (
        <UserContext.Provider value={{ updateAccount, validateEmailToken, resendEmailValidationToken }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext };