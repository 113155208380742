import React from 'react';

import { DoneSuccess } from '@design-system';

import { useNavigate } from 'react-router-dom';

const InvoiceDone: React.FC = () => {

    const navigate = useNavigate();

    const goto = () => setTimeout(() => navigate('/'), 2000)

    React.useEffect(() => {

     goto();

    }, []);

    return (

        <React.Fragment>
            <DoneSuccess
                title='Nota Fiscal Adicionada!'
            />
        </React.Fragment>
    );
}

export { InvoiceDone };