import { createTheme } from '@mui/material/styles';

import { atacadao } from './theme-colors';

declare module '@mui/material/styles' {

    interface Palette {
        gifterr: string;
        atacadao: {
            colors: {
                primary: {
                    30: string;
                    20: string;
                    10: string;
                    pure: string;
                };
                highlight: {
                    30: string;
                    20: string;
                    10: string;
                    pure: string;
                };
            }
        };
        purple: {
            heliotrope: string;
        };
        green: {
            lemon: string;
            success: string;
            apple: string;
            pureapple: string;
        };
        blue: {
            neon: string;
        }
        pink: string;
        black: {
            50: string;
            100: string;
            150: string;
            200: string;
            300: string;
            400: string;
            500: string;
            600: string;
            700: string;
            800: string;
            900: string;
            1000: string;
        };
        gray: {
            clouds: string;
        };
    }

    interface PaletteOptions {
        gifterr: string;
        atacadao: {
            colors: {
                primary: {
                    30: string;
                    20: string;
                    10: string;
                    pure: string;
                };
                highlight: {
                    30: string;
                    20: string;
                    10: string;
                    pure: string;
                };
            }
        };
        purple: {
            heliotrope: string;
        };
        green: {
            lemon: string;
            success: string;
            apple: string;
            pureapple: string;
        };
        blue: {
            neon: string;
        };
        pink: string;
        black: {
            50: string;
            100: string;
            150: string;
            200: string;
            300: string;
            400: string;
            500: string;
            600: string;
            700: string;
            800: string;
            900: string;
            1000: string;
        };
        gray: {
            clouds: string;
        };
    }
}

declare module '@mui/material/styles/createTypography' {
    interface FontStyle {
        fontProximaNova: string;
    }
}

const typography =
{
    typography: {
        fontProximaNova: [
            "proxima-nova, sans-serif;",
        ].join(","),
    },
};

const theme = createTheme({
    components: {
        MuiInput: {
            defaultProps: {
                color: 'error'
            }
        },
        MuiTextField: {
            defaultProps: {
                color: 'warning'
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#212121',
                    "&.Mui-focused": {
                        color: '#212121 !important',
                    }
                }
            }
        },
        MuiRadio: {
            defaultProps: {
                color: 'success'
            },
        styleOverrides: {
            root: {
                color: atacadao.colors.primary[30],
            },
        }
        },
        MuiInputBase: {

            styleOverrides: {

                root: {
                    "&.Mui-focused fieldset": {
                        borderColor: `${atacadao.colors.primary[30]} !important`,
                    },
                }
            }
        },
        MuiButton: {
            defaultProps: {
                size: 'large',
                disableRipple: true,
                sx: {
                    fontSize: 16,
                    textTransform: 'uppercase',

                },
                style: {
                    boxShadow: 'none',
                    paddingLeft: 30,
                    paddingRight: 30,
                    fontWeight: 'normal',
                    textTransform: 'uppercase',
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontSize: '4rem',
                    
                    "&.mui-primary": {
                        backgroundColor: atacadao.colors.primary[30],
                        '&:hover': {
                            backgroundColor: atacadao.colors.primary.pure,
                        }
                    },
                    "&.mui-highlight": {
                        backgroundColor: atacadao.colors.highlight.pure,
                        '&:hover': {
                            backgroundColor: atacadao.colors.highlight[30],
                        }
                    },
                    "&.mui-info": {
                        backgroundColor: '#e0e0e0',
                        color: '#424242',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        }
                    }
                }
            }
        },
    },
    ...typography,
    palette: {
        gifterr: '#C4FF00',
        atacadao: {
            colors: {
                primary: {
                    30: '#3C8633',
                    20: '#69C95F',
                    10: '#ACDDA7',
                    pure: '#266200',
                },
                highlight: {
                    30: '#E55302',
                    20: '#FFAF00',
                    10: '#FFDEC1',
                    pure: '#F97501',
                },
            }
        },
        purple: {
            heliotrope: "#D972FD"
        },
        green: {
            lemon: '#C4FF00',
            success: '#00FF19',
            apple: '#05B439',
            pureapple: '#509B15',
        },

        blue: {
            neon: "#00F0FF"
        },
        pink: '#FF3CD4',
        black: {
            "50": "rgba(0.0, 0.0, 0.0, 0.05)",
            "100": "rgba(0.0, 0.0, 0.0, 0.10)",
            "150": "rgba(0.0, 0.0, 0.0, 0.15)",
            "200": "rgba(0.0, 0.0, 0.0, 0.20)",
            "300": "rgba(0.0, 0.0, 0.0, 0.30)",
            "400": "rgba(0.0, 0.0, 0.0, 0.40)",
            "500": "rgba(0.0, 0.0, 0.0, 0.50)",
            "600": "rgba(0.0, 0.0, 0.0, 0.60)",
            "700": "rgba(0.0, 0.0, 0.0, 0.70)",
            "800": "rgba(0.0, 0.0, 0.0, 0.80)",
            "900": "rgba(0.0, 0.0, 0.0, 0.90)",
            "1000": "rgba(0.0, 0.0, 0.0, 1)",
        },
        gray: {
            clouds: '#F4F4F4'
        },

    },
});

export { theme };