import React from 'react';

import { Done } from '@design-system';

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const SendEmailLink: React.FC = () => {

    const emailParam = new URLSearchParams(document.location.search).get("email");

    const navigate = useNavigate();

    return (

        <React.Fragment>
            <Done
                type='alert'
                title='E-Mail Alterado'
                message={
                    <p>
                        Foi enviado um link de confirmação
                        para seu e-mail <b>{emailParam}</b> 
                        {" "}clique no link para confirmar seu novo email
                    </p>
                }
            >
                <Button
                    sx={{
                        marginTop: '1.5625rem',
                    }}
                    className='mui-highlight'
                    variant="contained"
                    onClick={() => navigate('/')}
                >
                    Ok
                </Button>
            </Done>
        </React.Fragment>
    );
}

export { SendEmailLink };