import React from 'react';
import { ReactSVG } from 'react-svg';
import { Typography, useTheme, Box, Container } from '@mui/material';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Times } from '@img/icons';

import { Content, Head, Button, PageContent } from './styles';
import { useNavigate } from 'react-router-dom';

interface LayoutPageProps {
    children: React.ReactNode,
    pageTitle?: string;
    color?: string;
    closeArrow?: boolean;
    contentColor?: string;
    contentTitle?: string;
    backHistory?: any;
    backTo?: number;
    roundedContent?: boolean;
}

const Page: React.FC<LayoutPageProps> = ({
    pageTitle,
    color,
    closeArrow,
    contentColor,
    contentTitle,
    backTo,
    roundedContent,
    backHistory,
    children
}: LayoutPageProps) => {
    const navigate = useNavigate();

    const theme = useTheme();

    const typography = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '.875rem',
        width: '100%',
        padding: '8px 10px',
        backgroundColor: 'transparent',
        color: theme.palette.grey[800],
    }

    return (
        <Box
            sx={{
                height: '100%',
                backgroundColor: color ? color : theme.palette.common.white
            }}
        >
            <Head>
                <Button type='button' className='back' onClick={() => navigate(backTo ? backTo : -1)}>
                    <I icon={['far', 'chevron-left']} color={theme.palette.grey[900]} size='lg' />
                    <Typography
                        align='left'
                        variant='body1'
                        component='h1'
                        sx={{ typography }}
                    >
                        {pageTitle}
                    </Typography>
                </Button>

                {/* closeArrow && */}

                <Button type='button' className='times' onClick={
                    () => navigate('/')
                }>
                    <ReactSVG src={Times} />
                </Button>

            </Head>

            <Box
                sx={{
                    height: 'calc(100% - 44px)',
                    overflowX: 'auto',
                    backgroundColor: 'transparent',
                    borderRadius: roundedContent ? '1.25rem 1.25rem 0px 0px' : '0',
                }}
            >
                <Box
                    sx={{
                        height: 'calc(100% - 0px)',
                        overflowX: 'auto',
                        backgroundColor: 'transparent',
                        borderRadius: roundedContent ? '1.25rem 1.25rem 0px 0px' : '0',
                    }}
                >

                    {contentTitle && (
                        <Typography
                            align='left'
                            variant='body1'
                            component='h1'
                            sx={{
                                fontWeight: theme.typography.fontWeightMedium,
                                fontSize: '1rem',
                                color: theme.palette.grey[600],
                                marginTop: '1.875rem',
                                marginBottom: '.9375rem',
                            }}
                        >
                            <Container maxWidth="sm">

                                {contentTitle}

                            </Container>
                        </Typography>
                    )}

                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export { Page }