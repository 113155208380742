import { styled } from "@mui/material";

export const Main = styled('div')`
    border-bottom: 1px solid ${({theme}) => theme.palette.grey[100]};
`;

export const MenuItem = styled('button')`
    padding: .4688rem .3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 4.375rem;
    border: 0;
    background: transparent;
    cursor: pointer;
`;

export const Content = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .9375rem 0;
`;