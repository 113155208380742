import React from 'react';

import { Done } from '@design-system';

import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { useUser } from '@hooks';

const ConfirmEmail: React.FC = () => {

    const { validateEmailToken } = useUser();

    const tokenParam = new URLSearchParams(document.location.search).get("token") as string;

    const { palette, typography } = useTheme();

    const navigate = useNavigate();

    const validateEmail = async () => {

        try {

            const response = await validateEmailToken({ token: tokenParam });

            if(response.status === 400) throw new Error(response.message);

            navigate("/account/email/confirmed-email");

        } catch (error: any) {
            navigate("/account/email/expired-link");
        }

    }

    React.useEffect(() => {

        if (tokenParam) validateEmail();

    }, [])

    return (

        <React.Fragment>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%'
            }}>

                <CircularProgress sx={{ color: palette.atacadao.colors.primary[30] }} />

                <Typography
                    align='center'
                    variant='body1'
                    component='p'
                    sx={{
                        textAlign: 'center',
                        fontWeight: typography.fontWeightRegular,
                        color: palette.grey[800],
                        fontSize: '1.625rem'

                    }}
                >
                    Confirmando E-Mail..
                </Typography>
            </Box>

        </React.Fragment>
    );
}

export { ConfirmEmail };