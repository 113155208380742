import React from 'react';
import { Container } from '@mui/material';

import {
    Main,
    Content,
} from './styles';
import { AppLogo, MenuItem } from '@design-system';

import { sx } from './sx';

import { useAuth } from '@hooks';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {

    const { css } = sx();
    const { logout } = useAuth();

    const navigate = useNavigate();

    const logoutUser = async () => {
        try {
            const response = await logout();

            if (response.status >= 400) throw new Error();
            
            window.location.href = "/security-code";

        } catch (error: any) {
            return null;
        }
    }

    return (
        <Main>
            <Container maxWidth="sm">

                <Content>

                    <AppLogo align='flex-start' />

                    <MenuItem
                        icon='sign-out'
                        iconType='far'
                        color={css.icon.color}
                        fontSize={css.icon.size}
                        text='Sair'
                        onClick={logoutUser}
                    />

                </Content>
            </Container>
        </Main>
    )
}

export { Header };