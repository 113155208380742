import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Main, Menu } from './styles';

import { Button, useTheme } from '@mui/material';

import { sx } from './sx';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem } from '@design-system';

interface FixedMenuProps {
    showButtonNewInvoice?: boolean;
}

const FixedMenu: React.FC<FixedMenuProps> = ({ showButtonNewInvoice }: FixedMenuProps) => {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { palette, typography } = useTheme();

    const { css } = sx();

    const paths = {
        home: '/',
        invoices: '/invoice/sents',
        account: '/my-account',
        help: '/help-center'
    }

    return (
        <Main>

            {showButtonNewInvoice && (

                <Button
                    onClick={() => navigate('/invoice/new')}
                    variant="contained"
                    size='medium'
                    sx={{
                        backgroundColor: palette.atacadao.colors.primary[30],
                        marginTop: '1.25rem',
                        marginRight: '15px',
                        marginBottom: '20px',
                        textTransform: 'capitalize',
                        fontWeight: typography.fontWeightRegular,
                        justifyContent: 'flex-start',
                        minWidth: 'auto !important',
                        '&:hover': {
                            backgroundColor: palette.atacadao.colors.primary.pure,
                        }
                    }}
                    startIcon={<I icon={['far', 'plus']} color={palette.common.white} fontSize='16px' />}
                >
                    Enviar Nota
                </Button>
            )}

            <Menu>

                <MenuItem
                    icon='home-alt'
                    iconType={pathname === paths.home ? 'fas' : 'fal'}
                    color={pathname === paths.home ? palette.atacadao.colors.primary[30] : css.icon.color}
                    fontSize={css.icon.size}
                    text='Home'
                    link='/'
                    active={Boolean(pathname === paths.home)}
                />

                <MenuItem
                    icon='file-invoice'
                    iconType={pathname === paths.invoices ? 'fas' : 'fal'}
                    color={pathname === paths.invoices ? palette.atacadao.colors.primary[30] : css.icon.color}
                    fontSize={css.icon.size}
                    text='Notas'
                    link='/invoice/sents'
                    active={Boolean(pathname === paths.invoices)}
                />

                <MenuItem
                    icon='gift'
                    iconType='fal'
                    color={css.icon.color}
                    fontSize={css.icon.size}
                    text='Benefícios'
                    disabled
                    link=''
                    active={false}
                />

                <MenuItem
                    icon='question-circle'
                    iconType={pathname === paths.help ? 'fas' : 'fal'}
                    color={pathname === paths.help ? palette.atacadao.colors.primary[30] : css.icon.color}
                    fontSize={css.icon.size}
                    text='Ajuda'
                    link='/help-center'
                    active={Boolean(pathname === paths.help)}
                />

                <MenuItem
                    icon='user'
                    iconType={pathname === paths.account ? 'fas' : 'fal'}
                    color={pathname === paths.account ? palette.atacadao.colors.primary[30] : css.icon.color}
                    fontSize={css.icon.size}
                    text='Usuário'
                    link='/my-account'
                    active={Boolean(pathname === paths.account)}
                />

            </Menu>
        </Main>
    )
}

export { FixedMenu };