import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Page } from '@layout';

import $ from "jquery";
import "jquery-mask-plugin";

import { Button, CircularProgress, FormControl, FormHelperText, TextField, useTheme } from '@mui/material';
import {
  Form,
  MuiContainer,
} from './styles';

import { BottomPageAction, FormHelperCEP } from '@design-system';

import { useAuth, useUser, useUserAddress } from '@hooks';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface IFormInputs {
  zipcode: string;
}

const schema = yup.object({
  zipcode: yup.string().required("Digite um cep válido"),
}).required();

const UserAccountCep: React.FC = () => {
  const navigate = useNavigate();

  const originParam = new URLSearchParams(document.location.search).get("origin") as string;

  const { palette } = useTheme();

  const { user, loadUser } = useAuth();
  const { updateAccount } = useUser();

  const { setCep, address, searchCep } = useUserAddress();

  const [status, setStatus] = React.useState({
    updateZipcode: false
  })

  const { register, handleSubmit, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async ({ zipcode }: IFormInputs) => {

    if (!address.error) {
      setStatus(prev => prev = { ...prev, updateZipcode: true });

      try {
        const response = await updateAccount({ email: user.data.client.email as string, zipcode: zipcode, state: user.data.client.state ? user.data.client.state : address.state });

        if (response.status === 400) throw new Error(response.data.message);

        await loadUser();
        if (!!originParam) {
          navigate('/my-account/edit/cep/done');
        } else {
          toast.success('CEP Alterado!', { position: 'bottom-center' });
        }
        setStatus(prev => prev = { ...prev, updateZipcode: false });

      } catch (error: any) {
        setStatus(prev => prev = { ...prev, updateZipcode: false });
        toast.error(error?.message, { position: 'bottom-center' });
      }
    }

  };

  React.useEffect(() => {
    $('.zipcode').mask('00000-000') as any;
  }, []);

  React.useEffect(() => {
    if (user.isLoaded) {
      searchCep(user.data.client.zipcode as string)
    }
  }, []);


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Page
        pageTitle='Meus Dados / CEP'
        color={palette.common.white}
        contentColor={palette.common.white}
        contentTitle={!!originParam ? 'Cadastre seu CEP antes de prosseguir' : 'CEP Cadastrado'}
      >
        <MuiContainer maxWidth="sm">

          <FormControl error fullWidth variant="standard">
            <TextField
              id="filled-basic-zipcode"
              label="CEP"
              error={!!errors.zipcode}
              fullWidth
              variant="filled"
              aria-describedby="component-error-text"
              inputProps={{
                className: 'zipcode'
              }}
              defaultValue={user.isLoaded && user.data.client.zipcode as string}
              {...register("zipcode",
                {
                  required: true,
                  onChange: setCep
                })
              }
            />
            <FormHelperText id="filled-basic-zipcode">
              {errors.zipcode?.message}
            </FormHelperText>

          </FormControl>
          {!address.isLoading
            ? <FormHelperCEP address={address} />
            : <CircularProgress size={12} sx={{ color: palette.grey[600] }} />}


        </MuiContainer>

        <BottomPageAction>
          <Button
            onClick={() => console.log('Done!')}
            className='mui-primary'
            variant="contained"
            type='submit'
            disabled={status.updateZipcode}
          >
            {status.updateZipcode ? "Aguarde" : "Salvar"}
          </Button>
        </BottomPageAction>
      </Page>
    </Form>
  )
}

export { UserAccountCep };