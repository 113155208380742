const atacadao = {
    colors: {
        primary: {
            30: '#3C8633',
            20: '#69C95F',
            10: '#ACDDA7',
            pure: '#266200',
        },
        highlight: {
            30: '#E55302',
            20: '#FFAF00',
            10: '#FFDEC1',
            pure: '#F97501',
        },
    }
}

export { atacadao }