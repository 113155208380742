import { Container, styled } from "@mui/material";

export const MuiContainer = styled(Container)``;

export const List = styled('div')`
    & > .list-invoice-item {
        margin-bottom: .9375rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
