
import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Box, Typography, useTheme } from '@mui/material';

import {
  ListItem,
  Columns,
  Column,
  Icon,
  PreviewInfo,
  Item,
  ItemIcon,
  Value,
  ButtonToggle,
} from './styles';

import { RealSymbol } from '@img/icons';
import {InvoiceListItemProps} from '@interfaces';

const InvoiceListItem: React.FC<{ data: InvoiceListItemProps }> = ({ data }: { data: InvoiceListItemProps }) => {
  const { id, price, date, invoiceNumber } = data;
  const { palette, typography } = useTheme();

  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <ListItem key={`${id}-${invoiceNumber}`} className='list-invoice-item'>
      <Columns onClick={() => setOpen(prev => !prev)}>
        <Column className='column'>
          <Icon>
            <I icon={['fal', 'file-invoice']} color={palette.atacadao.colors.primary[30]} fontSize='1.375rem' />
          </Icon>
          <PreviewInfo>
            <Item className='symbol'>
              <ItemIcon>
                <img src={RealSymbol} alt="Valor" />
              </ItemIcon>
              <Value className='price-symbol'>
                {price ?? '00.00'}
              </Value>
            </Item>
            <Item>
              <ItemIcon>
                <I icon={['far', 'calendar-check']} color={palette.grey[600]} />
              </ItemIcon>
              <Value>
                {date ?? '00/00/0000'}
              </Value>
            </Item>
          </PreviewInfo>
        </Column>

        <Column className='column'>
          <ButtonToggle type='button'>
            <I icon={['far', open ? 'chevron-up' : 'chevron-down']} color={palette.grey[600]} size='lg' />

          </ButtonToggle>
        </Column>
      </Columns>
      {open && (
        <Box
          sx={{
            marginTop: '1.25rem'
          }}
        >
          <Typography
            align='left'
            variant='body1'
            component='p'
            sx={{
              fontWeight: typography.fontWeightMedium,
              fontSize: '.875rem',
              color: palette.common.black,
              marginBottom: '.25rem',
            }}
          >
            Numero Identificador
          </Typography>
          <Typography
            align='left'
            variant='body1'
            component='p'
            sx={{
              fontWeight: typography.fontWeightRegular,
              fontSize: '.875rem',
              color: palette.common.black,
              wordBreak: 'break-all',
            }}
          >
            {invoiceNumber ?? '...'}
          </Typography>
        </Box>
      )}
    </ListItem>
  )
}

export { InvoiceListItem };