import { Routes as AppRoutes, Route, Navigate, useNavigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
    Home,
    Terms,
    UserAccount,
    UserAccountCep,
    UserAccountEmail,
    TypeSecurityCode,
    SecurityCodeDone,
    CompleteAccountDataDone,
    InvoiceDone,
    InvoiceError,
    InvoiceNumberError,
    SendEmailLink,
    ConfirmedEmail,
    ConfirmEmail,
    ExpiredLink,
    InvoicesListSent,
    HelpCenter,
    QuestionAnswer,
    SendInvoice,
    InvoiceScanQRcode,
    TypeInvoiceCode,
    PreEnter,
    CreateAccount,
    AddAccountAddressDone,
} from '@pages';

import { useAuth } from './hooks/useAuth';
import { UserData } from '@interfaces';

function ProtectedRoute({ user }: { user: UserData }) {

    if (!user.data && user.isLoaded) {
        return <></>; //<Navigate to={"/security-code"} replace />
    }

    return <Outlet />;
}

const Routes = () => {

    const { user } = useAuth();

    return (
        <AppRoutes>

            <Route element={<ProtectedRoute user={user} />}>
                {/* HOME */}
                <Route index element={<Home />}></Route>

                {/* help center */}
                <Route path="/help-center" element={<HelpCenter />} />
                <Route path="/help-center/:questionAnswer" element={<QuestionAnswer />} />

                {/* Terms */}
                <Route path="/terms" element={<Terms />} />

                {/* Account */}
                <Route path="/my-account" element={<UserAccount />} />
                <Route path="/my-account/edit/cep" element={<UserAccountCep />} />
                <Route path="/my-account/edit/cep/done" element={<AddAccountAddressDone />} />
                <Route path="/my-account/edit/email" element={<UserAccountEmail />} />
                <Route path="/account/email/send-confirmation-link" element={<SendEmailLink />} />
                <Route path="/account/email/confirmed-email" element={<ConfirmedEmail />} />
                <Route path="/account/email/confirm-email" element={<ConfirmEmail />} />
                <Route path="/account/email/expired-link" element={<ExpiredLink />} />

                <Route path="/pre" element={<PreEnter />} />

                {/* Invoice */}
                <Route path="/invoice/sents" element={<InvoicesListSent />} />
                <Route path="/invoice/new" element={<SendInvoice />} />
                <Route path="/invoice/new/qrcode/scan" element={<InvoiceScanQRcode />} />
                <Route path="/invoice/new/invoice-code/type" element={<TypeInvoiceCode />} />
                <Route path="/invoice/done" element={<InvoiceDone />} />
                <Route path="/invoice/qrcode-error" element={<InvoiceError />} />
                <Route path="/invoice/invoice-number-error" element={<InvoiceNumberError />} />
            </Route>

            {/* Login */}
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/create-account/done" element={<CompleteAccountDataDone />} />
            <Route path="/security-code" element={<TypeSecurityCode />} />
            <Route path="/security-code/done" element={<SecurityCodeDone />} />

            <Route path='*' element={<h1>Página não encontrada</h1>} />
        </AppRoutes>
    )
}

export default Routes;