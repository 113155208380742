import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import {
  Header,
  Icon,
  MuiContainer,
  Columns,
  Column,
} from './styles';

import { AppLogo, BottomPageAction } from '@design-system';
import { backendAPI } from '@services';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks';

interface TermsProps {
  message?: string;
  regulament?: {
    _id: string;
    content: string;
    version: number;
    active: boolean;
    date: string;
    name: string;
  };
  isLoaded: boolean;
}

const Terms: React.FC = () => {

  const { palette, typography } = useTheme();

  const navigate = useNavigate();

  const { user } = useAuth();

  const [terms, setTerms] = React.useState<TermsProps>({} as TermsProps);

  const loadTerms = async () => {

    setTerms(prev => prev = { isLoaded: false });

    try {
      const response: TermsProps = await backendAPI.regulament.loadActive();
      const isResponse = (!!response.regulament);

      if (!isResponse) throw new Error();

      setTerms(prev => prev = { ...response, isLoaded: true });

    } catch (error) {
      setTerms(prev => prev = { isLoaded: false });
    }

  }

  const acceptTerms = async () => {

    try {

      await backendAPI.regulament.accept({ regulamentID: terms.regulament?._id as string });

      navigate("/pre");

    } catch (error) {
      console.log("TERMS ACCEPT ERROR: ", error);

    }
  }


  React.useEffect(() => {

    loadTerms();

  }, []);
  
  return (

    <>
      <Header>
        <AppLogo align='center' />
        <Box
          sx={{
            marginTop: '.9375rem'
          }}>
          <Columns>
            <Column>
              <Icon>
                <I icon={['fal', 'file-invoice']} color={palette.atacadao.colors.primary[30]} fontSize='1.375rem' />
              </Icon>
            </Column>
            <Column>
              <Typography
                align='left'
                variant='body1'
                component='h1'
                sx={{
                  color: palette.grey[900],
                  fontWeight: typography.fontWeightMedium,
                  fontSize: '16px',
                }}
              >
                Termos de uso
              </Typography>
              <Typography
                align='left'
                variant='body1'
                component='h1'
                sx={{
                  color: palette.grey[600],
                  fontWeight: typography.fontWeightRegular,
                  fontSize: '12px',
                }}
              >
                Ultima Atualização: {terms.regulament?.date?.split("T")[0]}
              </Typography>
            </Column>
          </Columns>
        </Box>
      </Header>

      <MuiContainer maxWidth="sm">

        <Box
          sx={{
            margin: '1.875rem 0'
          }}>

          <div dangerouslySetInnerHTML={{ __html: terms.regulament?.content as string }} />

        </Box>

      </MuiContainer>

      <BottomPageAction>
        <Stack direction="row" justifyContent="center" width='100%' spacing={2}>

          {/* <Button
            onClick={() => console.log('Done!')}
            variant="outlined"
            sx={{
              minWidth: 'auto !important',
              backgroundColor: palette.common.white,
              color: palette.grey[700],
              borderColor: palette.grey[400],
              '&:hover': {
                borderColor: palette.grey[400],
              }
            }}
            fullWidth
          >
            Recusar
          </Button> */}

          <Button
            onClick={acceptTerms}
            className='mui-primary'
            variant="contained"
          >
            Aceitar
          </Button>
        </Stack>
      </BottomPageAction>
    </>
  )
}

export { Terms };