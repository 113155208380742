

import { AxiosInstance } from "axios";

const registerNfe = async (axiosInstance: AxiosInstance, nfeCode?: string, qrcodeContent?: string, state?: string) => {

        try {
            const response = await axiosInstance
                .post('/nfe', {
                    nfeCode,
                    qrcodeContent,
                    state
                });
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            } else {
                return error.message;
            }
        }  
}

export {registerNfe};