import React from 'react';

import { DoneSuccess } from '@design-system';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks';

const CompleteAccountDataDone: React.FC = () => {

    const navigate = useNavigate();

    const goto = () => setTimeout(() => navigate('/terms'), 2000);

    React.useEffect(() => {

        goto();

    }, []);

    return (

        <React.Fragment>
            <DoneSuccess
                title='Seus Dados Foram Salvos!'
            />
        </React.Fragment>
    );
}

export { CompleteAccountDataDone };