import React from 'react';

import AtacadaoLogo from '@img/atacadao-logo.svg';

import { Main, LogoImage } from './styles';

import { MainProps } from "./interfaces";

const AppLogo: React.FC<MainProps> = ({ align }: MainProps) => {

    return (
        <Main align={align ?? "center"}>
            <LogoImage src={AtacadaoLogo} alt="Atacadão" />
        </Main>
    )
}

export { AppLogo }