import React from 'react';

import { Done } from '@design-system';

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth } from '@hooks';

const ConfirmedEmail: React.FC = () => {

    const { loadUser } = useAuth();

    const navigate = useNavigate();

    const loadUserToRedirect = async () => {
        await loadUser();
        navigate('/')
    }

    return (

        <React.Fragment>
            <Done
                type='success'
                title='E-mail confirmado'
                message='Seu email foi confirmado com sucesso!
                Clique no botão abaixo para ir para a home.'
            >
                <Button
                    sx={{
                        marginTop: '1.5625rem',
                    }}
                    className='mui-primary'
                    variant="contained"
                    onClick={loadUserToRedirect}
                >
                    Ir para a home
                </Button>
            </Done>
        </React.Fragment>
    );
}

export { ConfirmedEmail };