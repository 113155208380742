import React from 'react';

import cep from 'cep-promise';

import { debounce } from "lodash";

import { CepProps } from '@interfaces';

const useUserAddress = () => {

    const searchCep = React.useRef(debounce((value: string) => searchUserCep(value), 1000)).current;

    const [address, setAddress] = React.useState<CepProps>({ isLoading: true } as CepProps);

    const searchUserCep = async (value: string) => {

        try {

            const response: any = await cep(value);

            setAddress(prev => prev = { ...response, isLoading: false });

        } catch (error: any) {
            setAddress(prev => prev = {
                cep: '',
                city: '',
                neighborhood: '',
                state: '',
                street: '',
                error: true,
                isLoading: false,
            })

        }

    };

    const setCep = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value !== "") {
            searchCep(value)
        } else {
            setAddress(prev => prev = {
                cep: '',
                city: '',
                neighborhood: '',
                state: '',
                street: '',
                error: true,
                isLoading: false,
            })
        }
    }

    return { setCep, searchCep, address }
}

export { useUserAddress };