import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';


import { Done } from '@design-system';

import { useNavigate } from 'react-router-dom';
import { Button, useTheme } from '@mui/material';

const InvoiceError: React.FC = () => {

    const { palette, typography } = useTheme();


    const navigate = useNavigate();

    const goto = () => setTimeout(() => navigate('/groups/new-group'), 600)

    React.useEffect(() => {

        // goto();

    }, []);

    return (

        <React.Fragment>
            <Done
                type='alert'
                title='QRCode inválido!'
                message='Ocorreu um erro ao ler o QRCode da sua nota. Tente digitar o código manualmente.'
            >
                <Button
                    sx={{
                        marginTop: '1.5625rem',
                    }}
                    startIcon={<I icon={['fal', 'camera']} color={palette.common.white} fontSize='16px' />}
                    className='mui-primary'
                    variant="contained"
                    size='medium'
                    onClick={() => navigate('/invoice/new/qrcode/scan')}
                >
                    Escanear QRCode da Nota
                </Button>
            </Done>
        </React.Fragment>
    );
}

export { InvoiceError };