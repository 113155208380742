import { useTheme } from "@mui/material";

export const sx = () => {

    const { palette, typography } = useTheme();

    const css = {
        label: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '9px',
            textAlign: 'center'
        }
    }

    return { css }
}