
import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface BottomPageActionProps extends BoxProps {
  children: React.ReactNode;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

const BottomPageAction: React.FC<BottomPageActionProps> = (props: BottomPageActionProps) => {

  const { flexDirection, children } = props;

  const bg = 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8827731776304272) 25%, rgba(255,255,255,0.571848807882528) 75%, rgba(255,255,255,0) 100%)';

  return (
    <Box
      sx={{
        background: bg,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1.875rem .9375rem',
        flexDirection: flexDirection ? flexDirection : 'row',
        zIndex: 99
      }}
      {...props}
    >

      {children}

    </Box>
  )
}

export { BottomPageAction };