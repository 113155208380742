import { styled, Container } from "@mui/material";

export const Main = styled('div')(({ theme }) => `
    background-color: ${theme.palette.common.white};
    height: 100%;
`);

export const MuiContainer = styled(Container)`
    height: 100%;
`;
export const Step = styled('div')`
    height: calc(100% - 104px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Form = styled('form')`
    /* height: calc(100% - 116px); */
    height: 100%;
`;

export const Content = styled('div')`
`;
