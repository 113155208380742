import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Button, Container, Typography, useTheme } from '@mui/material';
import {
  MuiContainer,
  Header,
  BoxPromo,
  BoxImage,
  Bottom,
  BottomContent,
} from './styles';

import { AppLogo } from '@design-system';

import SendInvoiceQRcode from '@img/sacola.png';
import CartaoPrePago from '@img/cartao-pre-pago.png';
import CreditoApag from '@img/apag.png';
import { useAuth } from '@hooks';
import { useNavigate } from 'react-router-dom';

const data = [
  {
    id: uuidv4(),
    image: SendInvoiceQRcode,
    title: 'Sacola retornável e caderno de ofertas'
  },
  {
    id: uuidv4(),
    image: CartaoPrePago,
    title: 'Cartão digital pré-pago (crédito para celular)'
  },
  {
    id: uuidv4(),
    image: CreditoApag,
    title: 'Crédito APAG'
  },
]

const PreEnter: React.FC = () => {

  const theme = useTheme();

  const { user } = useAuth();

  const navigate = useNavigate();

  const titleStyles = {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1rem',
    padding: '.625rem'
  }

  return (
    <>
      <Header>
        <AppLogo align='center' />
      </Header>

      <MuiContainer maxWidth="sm">

        <Typography
          align='center'
          variant='body1'
          component='h1'
          sx={{
            color: theme.palette.grey[900],
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '20px',
            paddingBottom: '1.875rem',
          }}
        >
          Cadastre Seus Cupons Fiscais e Ganhe Prêmios
        </Typography>

        {data.map(({ id, image, title }) => (
          <BoxPromo key={`${id}`}>
            <BoxImage>
              <img src={image} alt={title} />
            </BoxImage>
            <Typography
              align='left'
              variant='body1'
              component='h1'
              sx={titleStyles}
            >
              {title}
            </Typography>
          </BoxPromo>
        ))}

      </MuiContainer>

      <Bottom>
        <Container maxWidth="sm">
          <BottomContent>
            <Button
              onClick={() => navigate('/')}
              variant="text"
              endIcon={<I icon={['far', 'chevron-right']} />}
              sx={{
                color: theme.palette.grey[700],
                minWidth: 'auto !important'
              }}
            >
              Continuar
            </Button>
          </BottomContent>
        </Container>
      </Bottom>
    </>
  )
}

export { PreEnter };