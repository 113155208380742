import { useTheme } from "@mui/material";

export const sx = () => {

    const theme = useTheme();

    const css = {
        icons: {
            chevronRight: {
                color: theme.palette.black[300],
                lineHeight: 1,
            }
        },
        radio: {
            color: theme.palette.common.black,
            '&.Mui-checked': {
                color: theme.palette.common.black,
            },
        },
        typography: {
            title: {
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '1.5rem',
                margin: '20px 0 25px 0',
            },
            box_title: {
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: '.875rem',
                marginBottom: '.9375rem',
            },
        }
    }

    return { css }
};