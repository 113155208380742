import React from 'react';

import { Done } from '@design-system';

import { useNavigate } from 'react-router-dom';
import { Button, Stack, useTheme } from '@mui/material';
import { useUser } from '@hooks';
import { toast } from 'react-toastify';

const ExpiredLink: React.FC = () => {

    const { palette } = useTheme();

    const { resendEmailValidationToken } = useUser();
    const [status, setStatus] = React.useState({
        sendLink: false
    });

    const navigate = useNavigate();

    const resend = async () => {
        setStatus(prev => prev = { ...prev, sendLink: true });
        try {
            const response = await resendEmailValidationToken();

            if (response.status === 200) {


                toast.success("Um novo email de confirmação foi enviado para o email cadastrado.",
                    {
                        position: 'bottom-center',
                        onClose: () => navigate('/'),
                        onOpen: () => setStatus(prev => prev = { ...prev, sendLink: false }),
                        delay: 2000
                    });
            }

        } catch (error) {
            setStatus(prev => prev = { ...prev, sendLink: false });

        }
    }

    return (

        <React.Fragment>
            <Done
                type='error'
                title='Erro ao Validar E-Mail'
                message={
                    <span>
                        Link de confirmação de e-mail inválido ou expirado!<br />
                        Clique no botão abaixo para enviar um
                        novo link de confirmação para seu e-mail.
                    </span>
                }
            >
                <Stack direction="row" justifyContent="center" width='100%' spacing={2} sx={{ marginTop: '1.5625rem' }}>

                    <Button
                        onClick={() => navigate('/')}
                        variant="outlined"
                        size='medium'
                        sx={{
                            minWidth: 'auto !important',
                            backgroundColor: palette.common.white,
                            color: palette.grey[700],
                            borderColor: palette.grey[400],
                            '&:hover': {
                                borderColor: palette.grey[400],
                            }
                        }}
                        fullWidth
                    >
                        Ir Para Home
                    </Button>

                    <Button
                        sx={{
                            marginTop: '1.5625rem',
                        }}
                        size='medium'
                        className='mui-primary'
                        variant="contained"
                        fullWidth
                        onClick={resend}
                        disabled={status.sendLink}
                    >
                        {!status.sendLink ? 'Enviar' : 'Aguarde'}
                    </Button>

                </Stack>
            </Done>
        </React.Fragment>
    );
}

export { ExpiredLink };