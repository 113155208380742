import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';


import { Done } from '@design-system';

import { useNavigate } from 'react-router-dom';
import { Button, useTheme } from '@mui/material';

const InvoiceNumberError: React.FC = () => {

    const { palette, typography } = useTheme();


    const navigate = useNavigate();

    const goto = () => setTimeout(() => navigate('/groups/new-group'), 600)

    React.useEffect(() => {

        // goto();

    }, []);

    return (

        <React.Fragment>
            <Done
                type='alert'
                title='Número do Cupom Inválido!'
                message={
                    <p>
                        Ocorreu um erro ao ler o número do seu cupom fiscal.
                        <br />
                        Tente digitar o código manualmente.
                    </p>
                }
            >
                <Button
                    sx={{
                        marginTop: '1.5625rem',
                    }}
                    startIcon={<I icon={['fal', 'camera']} color={palette.common.white} fontSize='16px' />}
                    className='mui-primary'
                    variant="contained"
                    onClick={() => navigate('/invoice/new/qrcode/scan')}
                    size='medium'
                >
                    Escanear QRCode da Nota
                </Button>
                <Button
                    sx={{
                        marginTop: '15px',
                    }}
                    startIcon={<I icon={['fal', 'qrcode']} color={palette.grey[800]} />}
                    className='mui-info'
                    variant="contained"
                    onClick={() => navigate('/invoice/new/invoice-code/type')}
                    size='medium'
                >
                    Não Consigo Ler o QRCode
                </Button>
            </Done>
        </React.Fragment>
    );
}

export { InvoiceNumberError };