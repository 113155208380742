import { Container, styled } from "@mui/material";

export const MuiContainer = styled(Container)`
    padding-bottom: 1.875rem;
    height: calc(100% - 183px);
    overflow-y: auto;
`;

export const Header = styled('div')`
    background-color: ${({ theme }) => theme.palette.common.white};
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    padding: .875rem .9375rem;
`;

export const Form = styled('form')``;