import { Page } from '@layout';
import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { FormHelperText, useTheme } from '@mui/material';
import { Block } from '@design-system';
import { MuiContainer, Blocks } from './styles';
import { useNavigate } from 'react-router-dom';
import { FixedMenu } from '@components/patterns';
import { useAuth } from '@hooks';

const UserAccount: React.FC = () => {

  const navigate = useNavigate();

  const { user } = useAuth();

  const { palette } = useTheme();

  return (
    <Page pageTitle='Meus Dados' color={palette.common.white} contentTitle='Dados Cadastrados'>
      <MuiContainer maxWidth="sm">

        <Blocks>

          <Block
            title='E-Mail'
            message={(user.isLoaded && user.data.client.email)?.toString()}
            actionIcon={<I icon={['far', 'chevron-right']} color={palette.grey[600]} />}
            onClick={() => navigate('/my-account/edit/email')}
            complement={user.isLoaded && !user.data.client.emailValidated && <FormHelperText error id="filled-basic-email">
              E-Mail não validado!
            </FormHelperText>}
          />

          <Block
            title='CEP'
            message={(user.isLoaded && user.data.client.zipcode ? user.data.client.zipcode : 'Não cadastrado')?.toString()}
            actionIcon={<I icon={['far', 'chevron-right']} color={palette.grey[600]} />}
            onClick={() => navigate('/my-account/edit/cep')}
          />

        </Blocks>

      </MuiContainer>

      <FixedMenu showButtonNewInvoice />
    </Page>
  )
}

export { UserAccount };