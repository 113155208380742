import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { Page } from '@layout';
import { MuiContainer } from './styles';
import { FixedMenu } from '@components/patterns';

import QrReader from 'modern-react-qr-reader';
import { backendAPI } from '../../../../../../services/BackendAPI';
import {ToastContainer, toast} from 'react-toastify';
import { useAuth } from '@hooks';

const CURRENT_STATE = 'SP';

const InvoiceScanQRcode: React.FC = () => {

    const { palette, typography } = useTheme();
    
    const { user } = useAuth();
    
    const navigate = useNavigate();

    const onReadQrCode = async (qrcodeContent: string) => {
        toast.info('Cadastrando nota .. aguarde');
        
        try {
            const response = await backendAPI.nfe
                .registerNfeByQrCodeAndState(qrcodeContent, CURRENT_STATE);

            if (!response.message) {
                toast.error(response);
                return navigate('/invoice/qrcode-error');
            }

            navigate('/invoice/done');
        } catch (error: any) {
            toast.error(error.message)
            navigate('/invoice/qrcode-error');
        }
    }

    React.useEffect(() => {
        if(user.isLoaded && !user.data.client.zipcode) {
            navigate('/my-account/edit/cep?origin=invqrc');
        }
    })

    return (
        <Page pageTitle='Enviar Nota / Ler QRcode da Nota' color={palette.grey[50]}>
            <MuiContainer maxWidth="sm">
                <Box
                    sx={{
                        backgroundColor: palette.common.white,
                        textAlign: 'center',
                        padding: '.9375rem',
                        border: `.0625rem solid ${palette.grey[100]}`
                    }}>

                    <Typography
                        align='center'
                        variant='body1'
                        component='h1'
                        sx={{
                            fontWeight: typography.fontWeightMedium,
                            fontSize: '1.125rem',
                            color: palette.grey[800],
                            marginBottom: '5px'
                        }}
                    >
                        Aponte sua câmera para o QRCode
                    </Typography>
                </Box>
    
                <QrReader
                    style={{maxWidth: '400px', margin: '0 auto', marginTop: '30px'}}
                    facingMode={"environment"}
                    delay={500}
                    onScan={(result: any) => {if (!!result) onReadQrCode(result)}}
                    onError={(error: any) => console.log(error, 'ERROR')}
                />

                {/*<QRCodeBox>
                    <Box sx={{ ...borderStyle, top: 0, left: 0 }} />
                    <Box sx={{ ...borderStyle, top: 0, right: 0 }} />

                    <QRCodeBoxContent>
                        <QRCodeArea>
                            QR Code
                        </QRCodeArea>
                    </QRCodeBoxContent>
                    
                    <Box sx={{ ...borderStyle, bottom: 0, left: 0 }} />
                    <Box sx={{ ...borderStyle, bottom: 0, right: 0 }} />
                </QRCodeBox>*/}

            </MuiContainer>

            <FixedMenu />

            <ToastContainer/>
        </Page>
    );
}

export { InvoiceScanQRcode };