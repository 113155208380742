import { Container, OutlinedInput, styled } from "@mui/material";

export const MuiContainer = styled(Container)`
    margin-bottom: 9.125rem;
`;

export const Form = styled('form')`
    margin-bottom: 1.875rem;
`;

export const Input = styled(OutlinedInput)`
    & > input.MuiInputBase-input {
        font-size: .75rem;
    }

    & > fieldset {
        border: 0 !important;
    }
    
    &.Mui-focused fieldset {
        border: 0 !important;
    }
    
    &.field-stepper,
    &.field-country-code {
        & > input.MuiInputBase-input {
            font-weight: ${(({ theme }) => theme.typography.fontWeightRegular)};
            color: ${(({ theme }) => theme.palette.common.black)};
        }
    }
    &.field-country-code {
        & > fieldset,
        &.Mui-focused fieldset {
            border: .0625rem solid ${(({ theme }) => theme.palette.common.black)} !important;
            border-radius: 10px;

        }
        
    }
`;

export const Blocks = styled('div')`
    margin-bottom: 1.875rem;

    & > .block {
        margin-bottom: .9375rem;
    }
`;

export const BlockWraper = styled('div')`
    margin-bottom: 2.5rem;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    overflow: hidden;
    
    & > .block {
        border-radius: 0;
        border: 0;
        box-shadow: none;
    border-bottom: 1px solid ${(({ theme }) => theme.palette.black[50])};

        & > .cols {
            & > .col {

                & > .content {

                    & > .title {
                        font-size: 16px;
                        color: ${(({ theme }) => theme.palette.grey[900])};
                        font-weight: ${(({ theme }) => theme.typography.fontWeightRegular)};
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
`;

export const Label = styled('div')`
    padding-left: .9375rem;
    line-height: 1;
`;