import { styled, Container, IconButton, Theme, alpha } from "@mui/material";

import { svg } from '@utils';

interface IcButtonProps {
    svgHeight?: number;
    theme: Theme
}

export const Main = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    paddingBottom: '3.75rem',
}));

export const MuiContainer = styled(Container)``;

export const Actions = styled('div')`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.25rem 0;
    
    & > .mui-btn {
        & > .svg {
            div {
                svg {
                    path {
                        fill: ${(({ theme }) => theme.palette.common.white)}
                    }
                }
            }
        }
    }
`;

export const GiftBox = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 0 60px 0;
`;

export const Blocks = styled('div')`

    & > .block {
        margin-bottom: 1.25rem;
        
        &:last-child{
            margin-bottom: 0;
        }
    }
`;

export const SearchBar = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1.375rem'
});

export const Button = styled('button')`
    padding: .875rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 0;

    &.back {
        left: 0;
    }

    &.times {
        right: 0;
    }
`;