import { BackendAPIInterface, NewClient } from "@interfaces";
import axios, { AxiosError, AxiosInstance } from "axios"
import { acceptRegulament } from "./backend/acceptRegulament";
import { confirmEmail } from "./backend/confirmEmail";

import { confirmToken } from "./backend/confirmToken";
import { createClient } from "./backend/createClient";
import { listClientNfes } from "./backend/listClientNfes";
import { loadActiveRegulament } from "./backend/loadActiveRegulament";
import { loadClient } from "./backend/loadClient";
import { registerNfe } from "./backend/registerNfe";
import { requestToken } from "./backend/requestToken";
import { resendConfirmationEmail } from "./backend/resendConfirmationEmail";
import { updateClient } from "./backend/updateClient";
import { logout } from './backend/user';
import api from '../config/config.json';

const BACKEND_API: string = api.serverURL;

class BackendAPI {
    api: BackendAPIInterface;

    constructor() {
        const axiosInstance: AxiosInstance = axios.create({
            baseURL: BACKEND_API,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            withCredentials: true
        });

        axiosInstance.interceptors.response.use((response) => {
            return response;
        }, (error) => {

            if (window.location.href.indexOf('/create-account') !== -1 || window.location.href.indexOf('/create-account/done') !== -1) {
                if (error.response && error.response.data) {
                    throw new Error(error.response.data.message);
                } else {
                    throw new Error(error.message);
                }
            }

            if (error.response && error.response.status === 401 && window.location.
                href.indexOf('/security-code') === -1) {
                window.location.href = '/security-code' + window.location.search;
            }

            if (error.response && error.response.data) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error(error.message);
            }

        })

        this.api = {
            client: {
                async logoutUser() {
                    return await logout(axiosInstance);
                },
                async requestAccessToken(token: string, promotionCode: string) {
                    return await requestToken(token, promotionCode, axiosInstance);
                },
                async confirmAccessToken(token: string, promotionCode: string, accessCode: string) {
                    return await confirmToken(token, promotionCode, accessCode, axiosInstance);
                },
                async create(client: NewClient) {
                    return await createClient(client, axiosInstance);
                },
                async update(email: string, zipcode: string, state: string) {
                    return await updateClient(email, zipcode, state, axiosInstance);
                },
                async load() {
                    return await loadClient(axiosInstance);
                },
                async confirmEmail(confirmationToken: string) {
                    return await confirmEmail(confirmationToken, axiosInstance);
                },
                async resendConfirmationEmail() {
                    return await resendConfirmationEmail(axiosInstance);
                },
            },
            nfe: {
                async listClientNfes() {
                    return await listClientNfes(axiosInstance);
                },
                async registerNfeByCode(nfeCode: string) {
                    return await registerNfe(axiosInstance, nfeCode);
                },
                async registerNfeByQrCodeAndState(qrcodeContent: string, state: string) {
                    return await registerNfe(axiosInstance, undefined, qrcodeContent, state);
                },
            },
            regulament: {
                async loadActive() {
                    return await loadActiveRegulament(axiosInstance);
                },
                async accept({ regulamentID }) {
                    return await acceptRegulament(regulamentID, axiosInstance);
                },
            }
        }
    }
}

const instance = new BackendAPI();
const backendAPI = instance.api;
export { backendAPI };