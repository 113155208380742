import { styled } from "@mui/material";

export const Icon = styled('div')`
    margin-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .svg {
        line-height: 0;
    }
`;

export const Cols = styled('div')`

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > .col {
        
        &:first-child {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-right: .9375rem;
        }
    }
`;

export const AvatarWrapper = styled('div')`
    padding-right: .9375rem;
`;

export const Info = styled('div')``;

export const Col = styled('div')``;

export const ActionIcon = styled('div')`
    & > .svg {
        line-height: 0;
    }
`;

export const Complement = styled('div')`
    padding: .125rem 0 .3125rem 0;
    line-height: 1;
    position: relative;
    top: .125rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;
