import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import $ from "jquery";
import "jquery-mask-plugin";

import { Button, Box, Typography, useTheme, TextField, Grid } from '@mui/material';

import {
    Main,
    MuiContainer,
} from './styles';

import { FixedMenu, Header } from '@components/patterns';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks';

const Home: React.FC = () => {

    const { user } = useAuth();
    
    const navigate = useNavigate();
    const { palette, typography } = useTheme();

    React.useEffect(() => {
        $('.phone_with_ddd').mask('(00) 00000-0000');
        $('.cpf').mask('000.000.000-00', { reverse: true });
    }, []);

    return (
        <Main>

            <Header />

            <MuiContainer maxWidth="sm" sx={{ marginTop: '1.25rem' }}>

                <Box
                    sx={{
                        background: "linear-gradient(180deg, rgba(255, 171, 20, 1) 0%, rgba(255, 113, 0, 1) 100%)",
                        borderRadius: ".25rem",
                        padding: "1.25rem",
                    }}>

                    <Typography
                        align='left'
                        variant='body1'
                        component='p'
                        sx={{
                            fontWeight: typography.fontWeightMedium,
                            color: palette.common.white,
                            fontSize: '24px',
                            textShadow: "0px 3px 6px #00000029"
                        }}
                    >
                        Bem vindo
                    </Typography>
                    <Typography
                        align='left'
                        variant='body1'
                        component='p'
                        sx={{
                            fontWeight: typography.fontWeightRegular,
                            color: palette.common.white,
                            fontSize: '16px',
                            textShadow: "0px 3px 6px #00000029"
                        }}
                    >
                        Cadastre suas notas fiscais e
                        tenha acesso a benefícios incríveis
                    </Typography>

                    <Button
                        variant="contained"
                        size='medium'
                        onClick={() => navigate('/invoice/new')}
                        sx={{
                            backgroundColor: palette.atacadao.colors.primary[30],
                            marginTop: '1.25rem',
                            textTransform: 'capitalize',
                            fontWeight: typography.fontWeightRegular,
                            justifyContent: 'flex-start',
                            minWidth: 'auto !important',
                            '&:hover': {
                                backgroundColor: palette.atacadao.colors.primary.pure,
                            }
                        }}
                        startIcon={<I icon={['fal', 'file-invoice']} color={palette.common.white} fontSize='16px' />}
                    >
                        Enviar Nota
                    </Button>

                </Box>

                <Box
                    sx={{}}>

                    <Typography
                        align='left'
                        variant='body1'
                        component='h1'
                        sx={{
                            fontWeight: typography.fontWeightMedium,
                            fontSize: '1rem',
                            color: palette.grey[600],
                            marginTop: '1.75rem',
                            marginBottom: '.9375rem',
                        }}
                    >
                        Dados Cadastrados
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <TextField
                                id="filled-basic"
                                label="E-Mail"
                                fullWidth
                                variant="filled"
                                value={user.isLoaded ? user.data.client.document : ""}
                                size='small'
                                disabled
                                focused
                                inputProps={{ className: 'cpf' }}
                            />

                        </Grid>
                        <Grid item xs={12}>

                            <TextField
                                id="filled-basic"
                                label="Telefone"
                                fullWidth
                                variant="filled"
                                value={user.isLoaded ? user.data.client.cellphone : ""}
                                size='small'
                                disabled
                                focused
                                inputProps={{ className: 'phone_with_ddd' }}
                            />
                        </Grid>
                    </Grid>

                </Box>

            </MuiContainer>
            <FixedMenu />
        </Main>
    )
}

export { Home };