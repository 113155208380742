import * as yup from "yup";

const securityCodeFormschema = yup.object({
    type_code_1: yup.string()
        .required('')
        .min(1, '').max(1, ''),
    type_code_2: yup.string()
        .required('')
        .min(1, '').max(1, ''),
    type_code_3: yup.string()
        .required('')
        .min(1, '').max(1, ''),
    type_code_4: yup.string()
        .required('')
        .min(1, '').max(1, ''),
}).required();

export {securityCodeFormschema}