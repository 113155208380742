import { Container, styled } from "@mui/material";

export const MuiContainer = styled(Container)`
    margin-top: 1.875rem;
    padding-bottom: 5rem;
`;

export const QRCodeBox = styled('div')`
    position: relative;
    margin-top: .9375rem;
`;

export const QRCodeArea = styled('div')`
    position: relative;
    height: 300px;
    width: 100%;
    background-color: ${({theme}) => theme.palette.grey[50]};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const QRCodeBoxContent = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    z-index: 9;

    & > img {
        position: relative;
        width: 108%;
    }

`;
