import { styled, Container } from "@mui/material";

export const Header = styled('div')`
    background-color: ${({ theme }) => theme.palette.atacadao.colors.primary[30]};
    padding: 40px 15px 60px 15px;
`;

export const MuiContainer = styled(Container)`
    background-color: ${({ theme }) => theme.palette.common.white};
    height: calc(100% - 164px);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -30px;
    padding-top: 34px;
    padding-bottom: 34px;
    overflow-y: auto;
`;

export const Bottom = styled('div')`
    border-top: .0625rem solid ${({ theme }) => theme.palette.grey[200]};
    width: 100%;
`;

export const BottomContent = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;


export const BoxPromo = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-left: .25rem solid ${(({ theme }) => theme.palette.atacadao.colors.highlight.pure)};
    box-shadow: 0px .1875rem .375rem #00000029;
    border-radius: .25rem;
    overflow: hidden;
    margin-bottom: .9375rem;
    
    &:last-child {
        margin-bottom: 0;
    }
`;

export const BoxImage = styled('div')`
    max-width: 110px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & > img {
        width: auto;
    /* height: 100%;
    object-fit: cover;
    border: 0;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle; */
    }
`;
