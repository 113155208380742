import React, { useEffect, useState } from 'react';
import { Page } from '@layout';

import { useTheme } from '@mui/material';
import { InvoiceListItem, NotFoundMessage } from '@design-system';
import {
  MuiContainer,
  List,
} from './styles';

import { FixedMenu } from '@components/patterns';

import { InvoiceListItemProps } from '@interfaces';
import { ToastContainer, toast } from 'react-toastify';
import { backendAPI } from '../../../../services/BackendAPI';


const InvoicesListSent: React.FC = () => {
  const { palette } = useTheme();

  const [data, setData] = useState<InvoiceListItemProps[]>();

  useEffect(() => {
    loadNfes();
  }, []);

  const loadNfes = async () => {
    try {
      const response = await backendAPI.nfe.listClientNfes();

      if (response.nfes) {
        setData(response.nfes.map((nfe: any) => ({
          id: nfe.key,
          price: nfe.price ? nfe.price.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '',
          date: nfe.registerDate ? new Date(nfe.registerDate).toLocaleString() : '',
          invoiceNumber: nfe.key
        })));
      }

    } catch (error: any) {
      toast.error(error.message);
    }
  }

  return (
    <Page pageTitle='Notas Enviadas' color={palette.grey[50]} contentTitle='Minhas Notas Enviadas'>
      <MuiContainer maxWidth="sm">

        <List>
          {!data && <p>Nenhuma nota enviada</p>}
          {data && data.length > 0
            ? data.map((invoice) => (
              <InvoiceListItem key={invoice.id} data={invoice} />
            ))
            : <NotFoundMessage
              message="Voce não possui notas cadastradas"
            />}
        </List>

      </MuiContainer>

      <FixedMenu showButtonNewInvoice />
      <ToastContainer />
    </Page>
  )
}

export { InvoicesListSent };