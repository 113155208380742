import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Box, Typography, useTheme } from '@mui/material';

interface NotFoundMessageProps {
    message: string;
}

const NotFoundMessage: React.FC<NotFoundMessageProps> = ({ message }: NotFoundMessageProps) => {

    const { palette, typography } = useTheme();

    return (

        <Box
            sx={{
                backgroundColor: palette.common.white,
                textAlign: 'center',
                padding: '1.875rem .9375rem',
                border: `1px solid ${palette.grey[100]}`
            }}>

            <I icon={['fal', 'exclamation-triangle']} color={palette.grey[600]} fontSize='2.125rem' />

            <Typography
                align='center'
                variant='body1'
                component='h1'
                sx={{
                    fontWeight: typography.fontWeightMedium,
                    fontSize: '1.125rem',
                    color: palette.grey[600],
                    marginTop: '4px'
                }}
            >
                {message}
            </Typography>
        </Box>
    )
}

export { NotFoundMessage };