import { Page } from '@layout';
import { Box, FormControl, InputAdornment, Typography, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';

import { Blocks, BlockWraper, Form, Input, Label, MuiContainer } from './styles';
import { sx } from '../sx';
import { Block } from '@design-system';
import { FixedMenu } from '@components/patterns';
import { useNavigate } from 'react-router-dom';
import { pathURL } from '@utils';
import { faq } from '../data/faq';

const HelpCenter: React.FC = () => {

  const navigate = useNavigate();

  const { css } = sx();
  const theme = useTheme();

  return (
    <Page pageTitle='Central de Ajuda' color={theme.palette.common.white}>
      <MuiContainer maxWidth="sm">

        {faq.map(({ id, section, questions }) => (
          <Box key={`${id}`}>

            <Typography
              align='left'
              variant='body1'
              component='h1'
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '1rem',
                color: theme.palette.grey[600],
                marginTop: '1.875rem',
                marginBottom: '.9375rem',
              }}
            >
              {section}
            </Typography>

            <Blocks>

              {questions.map(({ id, title, message }) => (

                <Block
                  key={`${id}`}
                  title={title}
                  message={message}
                  actionIcon={<ChevronRightIcon sx={css.icons.chevronRight} />}
                onClick={() => navigate(`/help-center/${pathURL(title)}?id=${section}`)}
                />
              ))}

            </Blocks>
          </Box>
        ))}

      </MuiContainer>

      <FixedMenu showButtonNewInvoice />
    </Page>
  )
}

export { HelpCenter };