
import { AxiosInstance } from "axios";

const acceptRegulament = async (regulamentID: string, axiosInstance: AxiosInstance) => {

        try {
            const response = await axiosInstance
                .post('/regulament/accept', {
                    regulamentID
                });
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            } else {
                return error.message;
            }
        }  
}

export {acceptRegulament};