import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { MuiContainer } from './styles';
import { Box, Button, Typography, useTheme } from '@mui/material';

interface DoneSuccessProps {
    title: string;
}

const DoneSuccess: React.FC<DoneSuccessProps> = ({ title }: DoneSuccessProps) => {

    const { palette, typography } = useTheme();

    return (

        <Box
            sx={{
                backgroundColor: palette.atacadao.colors.primary[30],
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <MuiContainer maxWidth="sm">

                <Box
                    sx={{
                        height: '5.75rem',
                        width: '5.75rem',
                        borderRadius: '12.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `.125rem solid ${palette.common.white}`,
                        backgroundColor: 'transparent'
                    }}
                >
                    <I icon={['fal', 'check']} size="3x" color={palette.common.white} />
                </Box>

                <Box
                    sx={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        align='center'
                        variant='body1'
                        component='p'
                        sx={{
                            textAlign: 'center',
                            fontWeight: typography.fontWeightRegular,
                            color: palette.common.white,
                            fontSize: '1.625rem'

                        }}
                    >
                        {title}
                    </Typography>

                </Box>

            </MuiContainer>
        </Box>
    );
}

export { DoneSuccess };