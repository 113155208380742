import React from 'react';

import { Page } from '@layout';
import { Typography, useTheme } from '@mui/material';

import { MuiContainer } from './styles';
import { faq } from '../data/faq';
import { pathURL } from '@utils';
import { useParams } from 'react-router-dom';

const QuestionAnswer: React.FC = () => {

  const theme = useTheme();
  const params = useParams();

  const pageSection = new URLSearchParams(document.location.search).get('id');

  const filterQuestion = faq.map(({ questions }) => questions
    .filter((question) => pathURL(question.title) === pathURL(params.questionAnswer as string)))[0];

  const question = filterQuestion[0];

  return (
    <Page pageTitle={`Central de Ajuda / ${pageSection}`} color={theme.palette.common.white}>
      <MuiContainer maxWidth="sm">


        <Typography
          align='left'
          variant='body1'
          component='h1'
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '1rem',
            color: theme.palette.grey[600],
            marginTop: '1.875rem',
            marginBottom: '5px',
          }}
        >
          {question.title}
        </Typography>
        <Typography
          align='left'
          variant='body1'
          component='h1'
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '1rem',
            color: theme.palette.grey[800],
          }}
        >
          {question.message}
        </Typography>

      </MuiContainer>

    </Page>
  )
}

export { QuestionAnswer };