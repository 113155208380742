import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/authContext';
import { UserProvider } from './contexts/userContext';
import Routes from './Routes';

import "./assets/css/index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-calendar/dist/Calendar.css';

import { theme } from './themes';
import { ToastContainer } from 'react-toastify';

export default function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
        <ToastContainer />
      </UserProvider>
    </AuthProvider>
  );
}
