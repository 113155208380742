import { styled } from "@mui/material";

export const Content = styled('div')`
    height: calc(100% - 44px);
    overflow-x: auto;
    background-color: red;
    border-radius: 1.25rem 1.25rem 0px 0px;

`;

export const Head = styled('div')`
    background-color: ${({ theme }) => theme.palette.common.white};
    border-bottom: .0625rem solid ${({ theme }) => theme.palette.grey[100]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 44px;
    /* padding-bottom: 1.25rem; */
`;

export const Button = styled('button')`
    padding: .875rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    
    & > svg {
        top: .125rem;
    }

    &.back {
    }

    &.times {
        right: 0;
    }
`;

export const PageContent = styled('div')(({ theme }) => `
    height: calc(100% - 0px);
    overflow-x: auto;
    background-color: transparent;
    border-radius: 1.25rem 1.25rem 0px 0px;
    padding-top: 25px;
`);
