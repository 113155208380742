import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';


import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Page } from '@layout';
import { MuiContainer } from './styles';
import { FixedMenu } from '@components/patterns';

import SendInvoiceQRcode from '@img/send-invoice-qrcode.png';
import { useAuth } from '@hooks';

const SendInvoice: React.FC = () => {

    const { palette, typography } = useTheme();

    const { user } = useAuth();

    const navigate = useNavigate();

    const checkExistsUserAccountAddress = async () => {
        if (user.isLoaded && !user.data.client.zipcode) {
            navigate('/my-account/edit/cep?origin=invqrc');
        } else {
            navigate('/invoice/new/qrcode/scan');
        }
    }

    return (
        <Page pageTitle='Enviar Nota' color={palette.grey[50]}>
            <MuiContainer maxWidth="sm">
                <Box
                    sx={{
                        backgroundColor: palette.common.white,
                        textAlign: 'center',
                        padding: '.9375rem',
                        border: `1px solid ${palette.grey[100]}`
                    }}>

                    <Typography
                        align='center'
                        variant='body1'
                        component='h1'
                        sx={{
                            fontWeight: typography.fontWeightMedium,
                            fontSize: '1.125rem',
                            color: palette.grey[800],
                            marginBottom: '5px'
                        }}
                    >
                        Escanear QRCode
                    </Typography>

                    <Typography
                        align='center'
                        variant='body1'
                        component='h1'
                        sx={{
                            fontWeight: typography.fontWeightRegular,
                            fontSize: '13px',
                            color: palette.grey[800],
                        }}
                    >
                        Aponte a camera do seu celular para o
                        QRCode na nota fiscal.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        textAlign: 'center',
                        marginTop: '20px',

                        '& > img': {
                            width: '100%',
                            maxWidth: '16.5625rem',
                        }
                    }}>
                    <img src={SendInvoiceQRcode} alt='Enviar Nota' />
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        marginTop: '1.5625rem',
                    }}>

                    <Button
                        startIcon={<I icon={['fal', 'camera']} color={palette.common.white} fontSize='16px' />}
                        className='mui-primary'
                        variant="contained"
                        size='medium'
                        fullWidth
                        onClick={checkExistsUserAccountAddress}
                    >
                        Escanear QRCode da Nota
                    </Button>
                    <Button
                        sx={{
                            marginTop: '15px',
                        }}
                        startIcon={<I icon={['fal', 'qrcode']} color={palette.grey[800]} />}
                        className='mui-info'
                        variant="contained"
                        size='medium'
                        fullWidth
                        onClick={() => navigate('/invoice/new/invoice-code/type')}
                    >
                        Não Consigo Ler o QRCode
                    </Button>

                </Box>
            </MuiContainer>

            <FixedMenu />

        </Page>
    );
}

export { SendInvoice };