import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Page } from '@layout';

import { Button, FormControl, FormHelperText, TextField, useTheme } from '@mui/material';

import {
  MuiContainer,
  Form,
} from './styles';

import { BottomPageAction } from '@design-system';
import { useUser, useAuth } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IFormInputs {
  email: string
}

const schema = yup.object({
  email: yup.string().email("Digite um e-mail válido").required("Digite um e-mail válido"),
}).required();

const UserAccountEmail: React.FC = () => {

  const navigate = useNavigate();

  const { user, loadUser } = useAuth();
  const { updateAccount } = useUser();
  const { palette } = useTheme();

  const { register, handleSubmit, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const [status, setStatus] = React.useState({
    updateEmail: false
  })

  const onSubmit = async ({ email }: IFormInputs) => {

    setStatus(prev => prev = { ...prev, updateEmail: true });

    try {
      const response = await updateAccount({ email: email, zipcode: user.data.client.zipcode, state: user.data.client.state });

      if (response.status === 400) throw new Error(response.data.message);

      setStatus(prev => prev = { ...prev, updateEmail: false });

      await loadUser();
      navigate(`/account/email/send-confirmation-link?email=${email}`);

    } catch (error: any) {
      setStatus(prev => prev = { ...prev, updateEmail: false });
      toast.error(error?.message, { position: 'bottom-center' });
    }

  };

  const isEmailValidated = (user.isLoaded && !user.data.client.emailValidated);
  const isEmail = (user.isLoaded && !user.data.client.email);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      <Page
        pageTitle='Meus Dados / E-Mail'
        color={palette.common.white}
        contentColor={palette.common.white}
        contentTitle='E-Mail Cadastrado'
      >
        <MuiContainer maxWidth="sm">

          <FormControl error fullWidth variant="standard">
            <TextField
              id="filled-basic-email"
              label="E-Mail"
              error={!!errors.email || isEmailValidated && !isEmail}
              fullWidth
              variant="filled"
              aria-describedby="component-error-text"
              defaultValue={user.isLoaded && user.data.client.email as string}
              focused={isEmailValidated && !isEmail}
              {...register("email", { required: true })}
            />
            <FormHelperText id="filled-basic-email">
              {errors.email?.message}
            </FormHelperText>

            {isEmailValidated && !isEmail && (
              <FormHelperText id="filled-basic-email">
                E-Mail não validado!
              </FormHelperText>
            )}

          </FormControl>

        </MuiContainer>
        <BottomPageAction>
          <Button
            onClick={() => console.log('Done!')}
            className='mui-primary'
            variant="contained"
            type='submit'
            disabled={status.updateEmail}
          >
            {status.updateEmail ? "Aguarde" : "Salvar"}

          </Button>
        </BottomPageAction>
      </Page>
    </Form>
  )
}

export { UserAccountEmail };