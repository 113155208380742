import { alpha, styled } from "@mui/material";

export const ListItem = styled('div')`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 6px #00000014;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.palette.grey[50]};
    cursor: pointer;
`;

export const ButtonToggle = styled('button')`
    height: 38px;
    width: 38px;
    background-color: transparent;
    border: 0;
`;

export const Columns = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Column = styled('div')`
    &.column {
        &:nth-child(1) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
`;

export const Icon = styled('div')`
    background-color: ${({ theme }) => alpha(theme.palette.atacadao.colors.primary[30], 0.1)};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    width: 2.75rem;
    border-radius: .1875rem;
    margin-right: .625rem;
`;

export const PreviewInfo = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const Item = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1.25rem;

    &.symbol {
        position: relative;
        top: 2px;
    }

    &:first-child {
        margin-left: 0;
    }
`;

export const ItemIcon = styled('div')`
    line-height: 1;
`;

export const Value = styled('p')`
    font-size: .875rem;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: ${({ theme }) => theme.palette.grey[900]};
    margin-left: .25rem;
    
    &.price-symbol {
        position: relative;
        top: -2px;
        margin-left: .125rem;
    }
`;
