import React from 'react';

import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { Cols, Col, Icon, ActionIcon, Complement, AvatarWrapper, Info } from './styles';

interface BlockProps extends BoxProps {
    icon?: React.ReactNode;
    complement?: React.ReactNode;
    avatar?: React.ReactNode;
    actionIcon: React.ReactNode;
    title: string;
    message?: string;
}

const Block: React.FC<BlockProps> = (props: BlockProps) => {

    const { icon, message, title, complement, actionIcon, avatar } = props;

    const theme = useTheme();

    const typography = {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1rem'
    }

    return (
        <Box
            className='block'
            sx={{
                border: `.0625rem solid ${theme.palette.black[100]}`,
                borderRadius: '.25rem',
                boxShadow: '0px 0px .25rem rgba(0, 0, 0, 0.1);',
                padding: '15px 15px 15px 15px',
                backgroundColor: theme.palette.common.white,
                display: 'flex',
                cursor: 'pointer',
            }}
            {...props}
        >

            {icon && (
                <Icon className='icon'>
                    {icon}
                </Icon>
            )}
            <Cols className='cols'>
                <Col className='col'>
                    {avatar && (
                        <AvatarWrapper>
                            {avatar}
                        </AvatarWrapper>
                    )}
                    <Info className='content'>

                        <Typography
                            align='left'
                            variant='body1'
                            component='h1'
                            className='title'
                            sx={{
                                ...typography,
                                color: theme.palette.grey[800],
                                fontWeight: theme.typography.fontWeightMedium,
                                fontSize: avatar ? '.75rem' : '1rem',
                                lineHeight: 1,
                                paddingBottom: '.5rem',
                            }}
                        >
                            {title}
                        </Typography>

                        {complement && (
                            <Complement>
                                {complement}
                            </Complement>
                        )}

                        {message && (
                            <Typography
                                align='left'
                                variant='body1'
                                component='h2'
                                sx={{
                                    color: theme.palette.black[400],
                                    fontSize: '.6875rem',
                                }}
                            >
                                {message}
                            </Typography>
                        )}
                    </Info>
                </Col>
                <Col>
                    <ActionIcon>
                        {actionIcon}
                    </ActionIcon>
                </Col>
            </Cols>


        </Box>
    )
}

export { Block }