
import { AxiosInstance } from "axios";

const loadActiveRegulament = async (axiosInstance: AxiosInstance) => {

        try {
            const response = await axiosInstance
                .get('/regulament');
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            } else {
                return error.message;
            }
        }  
}

export {loadActiveRegulament};