import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { MuiContainer } from './styles';
import { Box, Button, Typography, useTheme } from '@mui/material';

interface DoneProps {
    type: "success" | "alert" | "error"
    title: string;
    message?: React.ReactNode;
    buttonColor?: string;
    action?: () => void;
    children?: React.ReactNode;
}

const Done: React.FC<DoneProps> = ({ type, title, message, buttonColor, action, children }: DoneProps) => {

    const { palette, typography } = useTheme();

    const typeStyles = {
        color: palette.grey[700],
        icon: 'check'
    }

    switch (type) {
        case "success":
            typeStyles.color = palette.atacadao.colors.primary[30];
            typeStyles.icon = 'check';
            break;

        case "alert":
            typeStyles.color = palette.atacadao.colors.highlight.pure;
            typeStyles.icon = 'exclamation';
            break;

        case "error":
            typeStyles.color = palette.error.dark;
            typeStyles.icon = 'exclamation-triangle';
            break;

        default:
            break;
    }

    return (

        <Box
            sx={{
                backgroundColor: palette.common.white,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <MuiContainer maxWidth="sm">

                <Box
                    sx={{
                        height: '5.75rem',
                        width: '5.75rem',
                        borderRadius: '12.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `.125rem solid ${typeStyles.color}`,
                        backgroundColor: 'transparent'
                    }}
                >
                    <I icon={['fal', typeStyles.icon as any]} size="3x" color={typeStyles.color} />
                </Box>

                <Box
                    sx={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >
                    {title && (
                        <Typography
                            align='center'
                            variant='body1'
                            component='p'
                            sx={{
                                textAlign: 'center',
                                fontWeight: typography.fontWeightRegular,
                                color: palette.grey[800],
                                fontSize: '1.625rem'

                            }}
                        >
                            {title}
                        </Typography>
                    )}

                    {message && (
                        <Typography
                            align='center'
                            variant='body1'
                            component='p'
                            sx={{
                                textAlign: 'center',
                                fontWeight: typography.fontWeightRegular,
                                color: palette.grey[600],
                                marginTop: '.5rem',

                            }}
                        >
                            {message}
                        </Typography>
                    )}

                    {children}
                    
                </Box>

                {/* {children} */}

            </MuiContainer>
        </Box>
    );
}

export { Done };