import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    Box,
    Typography,
    useTheme,
    alpha
} from '@mui/material';

interface ErrorsListProps {
    id: string;
    message: string;
}

interface ErrorBoxListProps {
    title: string;
    errors: ErrorsListProps[]
}

const ErrorBoxList: React.FC<ErrorBoxListProps> = ({ title, errors }: ErrorBoxListProps) => {

    const theme = useTheme();

    const { palette, typography } = theme;

    return (
        <>
            <Box
                sx={{
                    textAlign: 'center',
                    marginTop: '.625rem',
                    backgroundColor: alpha(palette.error.dark, 0.1),
                    padding: '.9375rem'

                }}
            >
                <Typography
                    align='left'
                    variant='body1'
                    component='p'
                    sx={{
                        fontWeight: typography.fontWeightMedium,
                        color: palette.error.dark,
                        fontSize: '.875rem',
                        marginBottom: '6px'
                    }}
                >
                    {title}
                </Typography>

                {errors.map(({ id, message }) => (

                    <Typography
                        key={`${id}`}
                        align='left'
                        variant='body1'
                        component='p'
                        sx={{
                            fontWeight: typography.fontWeightRegular,
                            color: palette.error.dark,
                            fontSize: '.8125rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            '& > svg': {
                                marginRight: '.3125rem'
                            }
                        }}
                    >
                        <I icon={['fas', 'circle']} fontSize='.25rem' />{message}
                    </Typography>
                ))}
            </Box>
        </>
    );
}

export { ErrorBoxList };