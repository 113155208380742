export const faq = [
    {
        id: 'a45523',
        section: 'Geral',
        questions: [
            {
                id: 'a45243',
                title: 'Lojas Participantes',
                message: 'Veja a lista de lojas participantes'
            },
            {
                id: 'a45163',
                title: 'Critérios Para Resgate',
                message: 'Veja todos os critérios para resgate dos prêmios '
            },
        ]
    },
]
