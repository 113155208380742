import React, { useEffect } from 'react';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import { BottomPageAction } from '@design-system';

import { toast } from 'react-toastify';

import {
    Box,
    Typography,
    useTheme,
    FormControl,
    Grid,
    TextField,
    Button,
} from '@mui/material';

import { AppLogo, ErrorBoxList } from '@design-system';

import {
    Main,
    MuiContainer,
    Step,
    Form,
    Content,
} from './styles';

import { backendAPI } from '../../../services/BackendAPI';
import { useAuth } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { securityCodeFormschema } from './yup';
import { errorsList } from './data';
import { SecurityCodeFormInputs } from './interfaces';

const TypeSecurityCode: React.FC = () => {

    const theme = useTheme();

    const { promotionCode, token, login } = useAuth();
    const navigate = useNavigate();

    const tokenKey = "@token";

    const [tokenLocal] = React.useState(() => {

        const getToken = localStorage.getItem(tokenKey);

        if (!!getToken) {
            return getToken;
        }

        return undefined;
    })

    const { palette, typography } = theme;

    const [pageErrors, setPageErrors] = React.useState({
        accessCode: false,
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm<SecurityCodeFormInputs>({
        resolver: yupResolver(securityCodeFormschema),
        mode: 'onTouched'
    });

    const onSubmit = async (data: SecurityCodeFormInputs) => {
        const { type_code_1, type_code_2, type_code_3, type_code_4 } = data;
        const accessCode = [type_code_1, type_code_2, type_code_3, type_code_4].join('');

        if (accessCode) {

            try {

                const response = await login(accessCode, token ? token : tokenLocal as string);

                if (response.status === 200) {

                    navigate('/security-code/done');
                    setPageErrors(err => err = { accessCode: false });

                } else {
                    throw new Error();
                }

            } catch (error: any) {
                setPageErrors(err => err = { accessCode: true });
            }
        }

    };

    const requestFirstAccessToken = async ({ message, accessTokenKey }: { message?: string, accessTokenKey?: string }) => {


        try {
            const response = await backendAPI.client.requestAccessToken(accessTokenKey as string, promotionCode);

            if (response === "Token de acesso inválido") throw new Error(response);

            reset({
                type_code_1: '',
                type_code_2: '',
                type_code_3: '',
                type_code_4: ''
            })

            toast.success(message, { position: 'bottom-center' })

        } catch (error: any) {
            toast.error("Ocorreu Um Erro ao Enviar o Código de Segurança.",
                {
                    position: 'bottom-center'
                })
        }
    }

    const saveTokenOnLocalStorage = () => localStorage.setItem("@token", token);

    useEffect(() => {

        if (!!token) {
            saveTokenOnLocalStorage();
            requestFirstAccessToken({ message: 'Código de Segurança Enviado!', accessTokenKey: token });
        }

    }, [token]);

    const inputStyle = {
        "input.MuiInputBase-input": {
            textAlign: 'center !important',
            fontSize: '2rem',
            padding: `.1875rem .875rem`,
            color: `${theme.palette.atacadao.colors.primary[30]} !important`,
            textTransform: 'uppercase',
        }
    }

    const handleChange = (e: any) => {
        const maxLength = 1;
        const { value, name } = e.target;
        const [, , fieldIndex] = name.split("_");

        // Check if they hit the max character length
        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < 4) {
                // Get the next input field
                const nextSibling: any = document.querySelector(
                    `input[name=type_code_${parseInt(fieldIndex, 10) + 1}]`
                );

                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
    }

    return (
        <Main>
            <MuiContainer maxWidth="sm">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Step>
                        <Content>
                            <AppLogo align='center' />

                            <Box sx={{
                                marginTop: '1.875rem',
                                marginBottom: '1.25rem'
                            }}>
                                <Typography
                                    align='center'
                                    variant='body1'
                                    component='p'
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: typography.fontWeightMedium,
                                        color: palette.atacadao.colors.primary[30],

                                    }}
                                >
                                    Código de verificação
                                </Typography>

                                <Typography
                                    align='center'
                                    variant='body1'
                                    component='p'
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: typography.fontWeightRegular,
                                        color: palette.grey[800],
                                        marginTop: '8px',

                                    }}
                                >
                                    Digite o código de verificação enviado
                                    para o número cadastrado
                                </Typography>
                            </Box>

                            <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                sx={{
                                    maxWidth: '320px',
                                    width: '100%',
                                    margin: '0 auto',
                                    display: 'flex'
                                }}
                                className="container"
                            >

                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" focused>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            error={!!errors.type_code_1}
                                            sx={inputStyle}
                                            {...register("type_code_1", { required: true, onChange: handleChange })}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" focused>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            error={!!errors.type_code_2}
                                            sx={inputStyle}
                                            {...register("type_code_2", { required: true, onChange: handleChange })}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" focused>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            error={!!errors.type_code_3}
                                            sx={inputStyle}
                                            {...register("type_code_3", { required: true, onChange: handleChange })}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" focused>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            error={!!errors.type_code_4}
                                            sx={inputStyle}
                                            {...register("type_code_4", { required: true, onChange: handleChange })}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                            {errors.type_code_4 && errors.type_code_4.type === "maxLength" && <span>Max length exceeded</span>}
                            {pageErrors.accessCode && (
                                <ErrorBoxList title='Código digitado é invalido!' errors={errorsList} />
                            )}

                            <Box
                                sx={{
                                    textAlign: 'center',
                                    marginTop: '1.25rem',
                                }}
                            >
                                <Typography
                                    align='center'
                                    variant='body1'
                                    component='p'
                                    sx={{
                                        fontWeight: typography.fontWeightRegular,
                                        color: palette.grey[800],
                                        fontSize: '.875rem'
                                    }}
                                >
                                    Não recebeu o código?
                                </Typography>

                                <Button
                                    variant="text"
                                    sx={{
                                        color: palette.atacadao.colors.highlight.pure,
                                        fontWeight: `${typography.fontWeightBold} !important`,
                                        fontSize: '.875rem'
                                    }}
                                    onClick={() => requestFirstAccessToken({ message: "Código de Segurança Reenviado!", accessTokenKey: String(tokenLocal) })}
                                >
                                    Enviar novamente
                                </Button>
                            </Box>
                        </Content>
                    </Step>

                    <BottomPageAction>
                        <Button
                            className='mui-primary'
                            variant="contained"
                            type='submit'
                        >
                            Enviar
                        </Button>
                    </BottomPageAction>

                </Form>

            </MuiContainer>
        </Main >
    )
}

export { TypeSecurityCode };