import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContextData, LoginResponse, UserData, NewClient, ResponseData } from "@interfaces";
import { backendAPI } from "../services/BackendAPI";
import { toast } from "react-toastify";

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<any> = ({ children }) => {

    const [user, setUser] = useState<UserData>({} as UserData);

    const tokenParam = new URLSearchParams(document.location.search).get("token") as string;

    const token = tokenParam;
    const promotionCode = 'promocao';

    useEffect(() => {
        loadUser();
    }, []);

    async function login(accessCode: string, token: string) {

        let response = {} as LoginResponse;

        try {

            await backendAPI.client
                .confirmAccessToken(token, promotionCode, accessCode);

            const responseData: LoginResponse = await backendAPI.client.load();

            const isLoaded = (!!responseData.client);

            if (!isLoaded) throw new Error('CLIL: Usuário não autorizado');

            response = {
                ...responseData,
                status: 200
            };

            setUser({ data: responseData, isLoaded: true });

            //SE NAO ACEITOU OS TERMOS VAI ABRIR APOS ENTRAR
            if (responseData.client && !responseData.client.acceptedRegulament
                && window.location.href.indexOf('/terms') === -1) {
                window.location.href = '/terms';
            }

        } catch (error) {

            response = {
                client: undefined as any,
                message: '',
                status: 401
            };

            setUser({ data: undefined as any, isLoaded: false });

        } finally {
            return response;
        }
    }

    async function logout() {

        let response = {} as ResponseData;

        try {

            const responseData = await backendAPI.client.logoutUser() as ResponseData;

            if (responseData.message !== "Cliente deslogado com sucesso") throw new Error(responseData.message)
            return response = {
                message: responseData.message,
                status: 200
            }

        } catch (error: any) {

            toast.error(error.message, { position: 'bottom-center' });

            return response = {
                message: error.message,
                status: 401
            }

        } finally {
            return response;
        }
    }

    async function loadUser() {
        try {
            const response: LoginResponse = await backendAPI.client.load();
            const isLoaded = (!!response.client);

            if (!isLoaded) throw new Error('UNAT: Usuário não autorizado');

            setUser({ data: response, isLoaded: true });

            if (response.client && !response.client.acceptedRegulament
                && window.location.href.indexOf('/terms') === -1) {
                window.location.href = '/terms';
            }

        } catch (error) {
            setUser({ data: undefined as any, isLoaded: true });

        }
    }

    async function createAccount({ data }: { data: NewClient }) {

        let response = {} as ResponseData;

        try {

            const responseData = await backendAPI.client.create(data) as ResponseData;

            if (responseData as any === "Usuário não autorizado") throw new Error(responseData as any);

            if (responseData as any === "Você já possui um cadastro") throw new Error(responseData as any);

            response = {
                ...responseData,
                status: 200
            };

        } catch (error: any) {

            response = {
                message: error.message,
                status: 401
            };

        } finally {
            return response;
        }
    }

    return (
        <AuthContext.Provider value={{ user, promotionCode, token, login, logout, loadUser, createAccount }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;