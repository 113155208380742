import { styled } from "@mui/material";

export const IconWrapper = styled('div')`
    min-height: 1.3125rem;
    width: 100%;
    display: flex;
    align-content: baseline;
    justify-content: center;
`;

export const Button = styled('button')`
    padding: .4688rem .3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 60px;
    border: 0;
    background: transparent;
    cursor: pointer;

    &:disabled {
        opacity: 0.4;
    }
`;