import { AxiosInstance } from "axios";

const confirmToken = async (token: string, promotionCode: string, 
    accessCode: string, axiosInstance: AxiosInstance) => {

        try {
            const response = await axiosInstance
                .post('/client/validate-access', {
                    promotionCode,
                    accessCode
                } ,{ 
                    headers: {
                        'Authorization': token
                    }
                });
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            } else {
                return error.message;
            }
        }  
}

export {confirmToken};