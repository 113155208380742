import React from 'react';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Page } from '@layout';

import { Button, FormControl, FormHelperText, TextField, useTheme } from '@mui/material';
import {
  Form,
  MuiContainer,
} from './styles';

import { BottomPageAction } from '@design-system';
import { backendAPI } from '../../../../../../services/BackendAPI';
import {ToastContainer, toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface IFormInputs {
  nfNumber: string;
}

const schema = yup.object({
  nfNumber: yup.string().required("Digite o número da nota fiscal"),
}).required();

const TypeInvoiceCode: React.FC = () => {

  const { palette } = useTheme();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: IFormInputs) => {
    try {
      const response = await backendAPI.nfe
        .registerNfeByCode(data.nfNumber);

      if (!response.message) {
        toast.error(response);
        return navigate('/invoice/invoice-number-error');
      }

      //toast.success(response.message);
      navigate('/invoice/done');
    } catch (error: any) {
      toast.error(error.message);
      navigate('/invoice/invoice-number-error');
    }
  };

  return (
    <Form  onSubmit={handleSubmit(onSubmit)}>

      <Page
        pageTitle='Enviar Nota'
        color={palette.common.white}
        contentColor={palette.common.white}
        contentTitle='Digite o Número do Cupom Fiscal'
      >
        <MuiContainer maxWidth="sm">

          <FormControl error fullWidth variant="standard">
            <TextField
              id="filled-basic-nfNumber"
              label="Número"
              fullWidth
              variant="filled"
              inputProps={{ className: 'nfNumber' }}
              {...register("nfNumber", { required: true, minLength: 44 })}
            />
            <FormHelperText id="filled-basic-nfNumber">
              {errors.nfNumber?.message}
            </FormHelperText>
          </FormControl>

        </MuiContainer>
        <BottomPageAction>
          <Button
            //onClick={() => console.log('Done!')}
            className='mui-primary'
            variant="contained"
            type='submit'
            size='medium'
          >
            Enviar
          </Button>
        </BottomPageAction>
      </Page>

      <ToastContainer/>
    </Form>
  )
}

export { TypeInvoiceCode };