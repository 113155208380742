import { styled } from "@mui/material";
import { MainProps } from "./interfaces";

export const Main = styled('div')<MainProps>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.align};
    /* padding: .9375rem; */
`;

export const LogoImage = styled('img')``;