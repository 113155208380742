import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faTimes,
    faLongArrowRight,
    faBraille,
    faSmileBeam,
    faPlus,
    faCalendarCheck,
    faSignOut,
} from "@fortawesome/pro-regular-svg-icons";

import {
    faPlusCircle,
    faCircle,
    faMinusCircle,
    faChevronDoubleRight,
    faBars,
    faHomeAlt as faHomeAltSolid,
    faGift as faGiftSolid,
    faUser as faUserSolid,
    faFileInvoice as faFileInvoiceSolid,
    faQuestionCircle as faQuestionCircleSolid
} from "@fortawesome/pro-solid-svg-icons";

import {
    faSignIn,
    faExclamationTriangle,
    faArrowCircleLeft,
    faCheck,
    faExclamation,
    faCamera,
    faQrcode,
    faFileInvoice,
    faHomeAlt as faHomeAltLight,
    faGift as faGiftLight,
    faUser as faUserLight,
    faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";


library.add(
    faCheck,
    faExclamation,
    faCamera,
    faQrcode,
    faFileInvoice,
    faFileInvoiceSolid,
    faHomeAltLight,
    faHomeAltSolid,
    faGiftLight,
    faGiftSolid,
    faUserLight,
    faUserSolid,
    faPlus,
    faQuestionCircle,
    faQuestionCircleSolid,
    faCalendarCheck,
    faChevronDown,
    faChevronUp,
    faExclamationTriangle,
    faSignOut,

    faCircle,
    faChevronLeft,
    faChevronRight,
    faPlusCircle,
    faMinusCircle,
    faChevronDoubleRight,
    faTimes,
    faLongArrowRight,
    faBars,
    faSignIn,
    faBraille,
    faSmileBeam,
    faArrowCircleLeft
);