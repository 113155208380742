import { AxiosInstance } from "axios";

const requestToken = async (token: string, promotionCode: string, axiosInstance: AxiosInstance) => {
    try {
        const response = await axiosInstance
            .post('/client/request-access', {
                promotionCode
            } ,{ 
                headers: {
                    'Authorization': token
                }
            });
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        } else {
            return error.message;
        }
    }  
}

export {requestToken};